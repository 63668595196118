import ReactOnRails from "react-on-rails";
import ClientFeed from "./App/components/client-feed";
import DocumentAuthorizations from "./App/routes/document-authorizations";
import ClientPortalLinks from "./App/components/client-portal-links"

ReactOnRails.register({
  ClientFeed,
  ClientPortalLinks,
  DocumentAuthorizations
});
