import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash.isempty';
import {
  Col,
  Card,
  CardBody,
  CardHeader,
  Button,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import axios from 'axios';
import { BASE_URL } from '../../../constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import CommentList from './CommentList';

const ClientFeed = ({ client_id, cannot_send_message }) => {
  const [otherMenu, setOtherMenu] = useState(false);
  const [requesting, setRequesting] = useState(false);
  const [clientFeed, setClientFeed] = useState({});
  const [value, setValue] = useState('');
  const [pageNum, setPageNum] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [commentBox, setCommentBox] = useState(null);
  const [loadButton, setLoadButton] = useState(false);

  useEffect(() => {
    if (!isEmpty(clientFeed) && pageNum === 1) {
      scrollToBottom();
    }
  }, [clientFeed]);

  const scrollToBottom = async () => {
    if (await commentBox) {
      commentBox.scrollTop = commentBox.scrollHeight + 1000;
    }
  };

  const prepend = (feedComments) => {
    let clientFeedCopy = { ...clientFeed };
    Object.keys(feedComments).map((date) => {
      const values = feedComments[`${date}`];
      if (`${date}` in clientFeedCopy) {
        clientFeedCopy[`${date}`] = values.concat(clientFeedCopy[`${date}`]);
        delete feedComments[`${date}`];
      }
    });
    return { ...feedComments, ...clientFeedCopy };
  };

  useEffect(() => {
    fetchClientFeed();
  }, [pageNum, sizePerPage]);

  const fetchClientFeed = async () => {
    setRequesting(true);
    await axios
      .get(
        `${BASE_URL}/api/v1/clients/${client_id}/feed_comments.json?page=${pageNum}&&per_page=${sizePerPage}`
      )
      .then((res) => {
        if (pageNum > 1) {
          setClientFeed(prepend(res.data.feed_comments));
          setRequesting(false);
        } else {
          setClientFeed(res.data.feed_comments);
          setRequesting(false);
        }
        setTotalPages(res.data.total_pages);
      });
  };

  const updateClientFeed = (feedComment) => {
    const date = Object.keys(feedComment)[0];
    const values = feedComment[`${date}`];
    let clientFeedCopy = { ...clientFeed };
    if (`${date}` in clientFeedCopy) {
      clientFeedCopy[`${date}`] = clientFeedCopy[`${date}`].concat(values);
    } else {
      clientFeedCopy[`${date}`] = values;
    }
    setClientFeed(clientFeedCopy);
  };

  const toggleAddComment = (value) => {
    const url = `${BASE_URL}/api/v1/clients/${client_id}/feed_comments.json`;
    axios
      .post(url, {
        content: value,
      })
      .then((res) => {
        updateClientFeed(res.data.feed_comment);
      });
  };

  const toggleLoadMore = () => {
    setLoadButton(true);
  };

  return (
    <Card>
      <CardHeader>
        <div className='d-flex justify-content-between align-items-center'>
          <div className='card-title'>
            <span>Company Feed</span>
          </div>
          <div>
            <ul className='m-0'>
              {requesting && <span>Refreshing...</span>}
              <li className='list-inline-item'>
                <Dropdown
                  isOpen={otherMenu}
                  toggle={() => {
                    setOtherMenu(!otherMenu);
                  }}
                >
                  <DropdownToggle tag='i' type='button'>
                    <i className='mdi mdi-dots-horizontal font-size-16' />
                  </DropdownToggle>
                  <DropdownMenu className='dropdown-menu-end'>
                    <DropdownItem href={`/f/clients/${client_id}/feed/`}>
                      View More
                    </DropdownItem>
                    <DropdownItem onClick={() => fetchClientFeed()}>
                      Refresh
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </li>
            </ul>
          </div>
        </div>
      </CardHeader>
      <CardBody>
        <div className='feed-comments-wrapper'>
          <PerfectScrollbar
            containerRef={(ref) => setCommentBox(ref)}
            onScrollUp={toggleLoadMore}
          >
            <div>
              {pageNum !== totalPages && loadButton && (
                <div>
                  <div className='chat-day-title'>
                    <span
                      className='title'
                      style={{ cursor: 'pointer' }}
                      onClick={() => setPageNum(pageNum + 1)}
                    >
                      Load More
                    </span>
                  </div>
                </div>
              )}
              <CommentList requesting={requesting} clientFeed={clientFeed} />
            </div>
          </PerfectScrollbar>
        </div>
        <div className='d-flex align-items-center'>
          <Col className='pe-0'>
            <div className='position-relative'>
              <textarea
                rows='2'
                className='form-control rounded chat-input'
                placeholder='Message'
                disabled={cannot_send_message}
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </div>
          </Col>
          <div className='col-auto'>
            <Button
              type='submit'
              color='secondary'
              disabled={cannot_send_message}
              className='chat-send btn-sm ms-2'
              onClick={() => {
                toggleAddComment(value);
                setValue('');
                scrollToBottom();
              }}
            >
              <span className='d-none d-sm-inline-block me-2'>Send</span>
              <i className='mdi mdi-send' />
            </Button>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export default ClientFeed;
