import { configureStore } from '@reduxjs/toolkit';
import createUserReducer from './slices/create-user-flow/createUser';
import stepReducer from './slices/create-user-flow/step';
import roleStepReducer from './slices/role-settings/roleStep';
import createRoleReducer from './slices/role-settings/createRole';
import authDocumentsReducer from './slices/documents/authorizationDocuments';
import authQuickActionsReducer from "./slices/documents/authorizationQuickActions";

export default configureStore({
  reducer: {
    createUser: createUserReducer,
    step: stepReducer,
    roleStep: roleStepReducer,
    createRole: createRoleReducer,
    authDocuments: authDocumentsReducer,
    authQuickActions: authQuickActionsReducer
  },
});
