import { Controller } from 'stimulus';
import axios from 'axios';
import Uppy from '@uppy/core';
import Dashboard from '@uppy/dashboard';
import StatusBar from '@uppy/status-bar';
import AwsS3 from '@uppy/aws-s3';
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/status-bar/dist/style.css';
import { BASE_URL } from '../packs/constants';

export default class extends Controller {
  static targets = ['attachmentIds'];

  connect() {
    const uppy = new Uppy({
      debug: false,
      autoProceed: false,
      allowMultipleUploads: true,
      restrictions: {
        maxFileSize: 5242880,
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
        allowedFileTypes: [
          'application/pdf',
          'application/zip',
          'application/vnd.ms-excel',
          'application/msexcel',
          'application/x-msexcel',
          'application/x-ms-excel',
          'application/x-excel',
          'application/x-dos_ms_excel',
          'application/xls',
          'application/x-xls',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/x-msword application/x-word',
          'application/msword application/word',
          'image/bmp',
          'image/gif',
          'image/vnd.microsoft.icon',
          'image/jpeg',
          'image/png',
          'image/svg+xml',
          'image/tiff',
          'image/webp',
          'application/vnd.oasis.opendocument.presentation', 
          'application/vnd.ms-powerpoint',
          'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        ],
        requiredMetaFields: [],
      }
    })
    .use(Dashboard, {
      inline: true,
      target: '#uppy-dashboard-container',
      replaceTargetContent: true,
      showProgressDetails: false,
      proudlyDisplayPoweredByUppy: false,
      disableStatusBar: true,
      note: 'Documents only, 1–10 files, up to 5 MB',
      height: 300,
      metaFields: [
        { id: 'name', name: 'Name', placeholder: 'file name' },
      ],
      browserBackButtonClose: false
    })
    .use(StatusBar, {
      target: '.uppy-input-progress',
      hideUploadButton: false,
      hideAfterFinish: false
    })
    .use(AwsS3, {
      companionUrl: '/api/v1/document_attachments/presign',
    })
    .on('upload-success', (file, data) => {
      const uploadedFileData = JSON.stringify({
        id: file.meta['key'].match(/^cache\/(.+)/)[1], // object key without prefix
        storage: 'cache',
        metadata: {
          size: file.size,
          filename: file.name,
          mime_type: file.type,
        },
      });
      
      const postData = {
        doc_data: uploadedFileData,
      };
      
      axios.post(`${BASE_URL}/api/v1/document_attachments`, postData).then((res) => {
        const ids = this.attachmentIdsTarget.value;
        this.attachmentIdsTarget.value = (ids.length == 0 ? res.data.id : ids + `,${res.data.id}`);
      });
    });
  }
}
