import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['folder', 'showSubCategory', 'folderList'];

  connect() {
    document
      .getElementById('categoryUL')
      .addEventListener('click', this.callback.bind(this));
  }

  folderChange(e) {
    let toggler = document.getElementsByClassName('arrow');
    for (let i = 0; i < toggler.length; i++) {
      toggler[i].addEventListener('click', this.callback);
    }
  }

  callback(e) {
    let element = e.target;
    try {
      element.parentElement.querySelector('.nested').classList.toggle('active');
    } catch (error) {
      console.log(error);
    }
  }
}
