import { Controller } from 'stimulus';
import axios from 'axios';
import template from 'lodash.template';

export default class extends Controller {
  static targets = ['employeeListTemplate', 'employeeListContainer'];

  connect() {
    this.appliedDaysFilter = '0-7days';
    this.appliedLastLoginFilter = 'logged_in';

    this.daysFilters = {
      '0-7days': {
        label: '7 Days',
        value: 7,
      },
      '7-15days': {
        label: '15 Days',
        value: 15,
      },
      '15-30days': {
        label: '30 Days',
        value: 30,
      },
    };
    this.lastLoginFilters = {
      logged_in: {
        value: 'Logged in',
      },
      not_logged_in: {
        value: 'Not Logged in',
      },
    };
    this.fetchEmployeeLoginStats(
      this.daysFilters[this.appliedDaysFilter].value,
      this.lastLoginFilters[this.appliedLastLoginFilter].value,
      this.element.getAttribute('employee_type')
    );

    this.requesting = false;
  }

  changeDaysFilter(e) {
    e.preventDefault();
    const employee_type = e.target.dataset.employee;
    this.appliedDaysFilter = e.target.dataset.filter;
    this.fetchEmployeeLoginStats(
      this.daysFilters[this.appliedDaysFilter].value,
      this.lastLoginFilters[this.appliedLastLoginFilter].value,
      employee_type
    );
    const buttons = e.target.parentElement.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('btn-primary');
      buttons[i].classList.add('btn-outline-primary');
    }
    e.target.classList.add('btn-primary');
    e.target.classList.remove('btn-outline-primary');
  }

  changeLastLoginFilter(e) {
    e.preventDefault();
    const employee_type = e.target.dataset.employee;
    this.appliedLastLoginFilter = e.target.dataset.filter;
    this.fetchEmployeeLoginStats(
      this.daysFilters[this.appliedDaysFilter].value,
      this.lastLoginFilters[this.appliedLastLoginFilter].value,
      employee_type
    );
    const buttons = e.target.parentElement.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('btn-primary');
      buttons[i].classList.add('btn-outline-primary');
    }
    e.target.classList.add('btn-primary');
    e.target.classList.remove('btn-outline-primary');
  }

  fetchEmployeeLoginStats(dayFilter, lastLoginFilter, employee_type) {
    this.template = template(this.employeeListTemplateTarget.innerHTML);
    const url = `/api/v1/employees`;
    const params = {
      page: 1,
      from_time: dayFilter,
      last_login: lastLoginFilter,
      employee_type: employee_type,
    };
    this.requesting = true;
    this.employeeListContainerTarget.innerHTML = '';
    axios.get(url, { params }).then((response) => {
      this.requesting = false;
      response.data.map((result) => {
        this.employeeListContainerTarget.innerHTML += this.template(result);
      });
    });
  }
}
