import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'slidingWrapper',
    'documentInfo',
    'openArrowIcon',
    'closeArrowIcon',
    'acknowledge',
    'submitBtn',
  ];

  connect() {
    this.documentInfoTarget.style.display = 'none';
    this.closeArrowIconTarget.style.display = 'none';
    this.submitBtnTarget.style.display = 'none';
    this.slidingWrapperVisible = false;
    this.documentInfoVisible = false;
  }

  toggleDocumentInfo(e) {
    e.preventDefault();
    if (!this.documentInfoVisible) {
      this.openArrowIconTarget.style.display = 'none';
      this.closeArrowIconTarget.style.display = 'flex';

      this.slidingWrapperTarget.style.display = 'inline';
      this.slidingWrapperVisible = true;

      this.documentInfoTarget.style.display = 'inline';
      this.documentInfoVisible = true;
    }
  }

  closeSlidingSidebar(e) {
    e.preventDefault();

    this.closeArrowIconTarget.style.display = 'none';
    this.openArrowIconTarget.style.display = 'flex';

    this.slidingWrapperTarget.style.display = 'none';
    this.documentInfoTarget.style.display = 'none';
    this.slidingWrapperVisible = false;
    this.documentInfoVisible = false;
  }

  openSlidingSidebar(e) {
    e.preventDefault();
    this.openArrowIconTarget.style.display = 'none';
    this.closeArrowIconTarget.style.display = 'flex';

    this.slidingWrapperTarget.style.display = 'inline';
    this.documentInfoTarget.style.display = 'inline';
    this.slidingWrapperVisible = true;
    this.documentInfoVisible = true;
  }

  toggleSubmitBtn() {
    // if (this.isHeadOfficeTarget.checked) {
    //   this.chooseHeadOfficeTarget.style.display = 'none';
    // } else {
    //   this.chooseHeadOfficeTarget.style.display = 'block';
    // }
  }
}
