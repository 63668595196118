import React, { useEffect, useState } from 'react';
import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { BASE_URL } from '../../../../constants';
import { stepList, filterNodes } from "../../../utils";
import useRoleHooks, { getColumnValues, sortDocuments, updateIdsToValues } from './hook';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { updateAuthDocuments } from '../../../redux/slices/documents/authorizationDocuments';

const Step01 = () => {
  const dispatch = useDispatch();
  const { currentStep, handleStepChange, data, clientId } = useRoleHooks();
  const [name, setName] = useState('');
  const [desc, setDesc] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [errorDesc, setErrorDesc] = useState(false);

  const handleNextStep = () => {
    if (!name.length) {
      setErrorName(true);
      return;
    }

    if (desc.length > 250) {
      setErrorDesc(true);
      return;
    }

    handleStepChange({ step: currentStep, payload: { name, desc } });
  };

  const handleChangeName = (e) => {
    const value = e.target.value;
    if (value && errorName) setErrorName(false);
    setName(value);
  };

  const handleChangeDesc = (e) => {
    const value = e.target.value;
    if (value && errorDesc) setErrorDesc(false);
    setDesc(value);
  };

  useEffect(() => {
    if (data.details && currentStep === stepList.step01) {
      setName(data.details.name);
      setDesc(data.details.desc);
    }
  }, [currentStep, data]);

  useEffect(() => {
    const fetchDocumentsList = () => {
      axios
        .get(
          `${BASE_URL}/api/v1/clients/${clientId}/custom_roles/get_folders.json`
        )
        .then((res) => {
          const expandedData = getColumnValues(res.data.folders, "value", []);
          dispatch(
            updateAuthDocuments({
              expandedData: expandedData.flat(),
              data: res.data.folders,
            })
          );
        });
    };

    fetchDocumentsList();
  }, []);

  return (
    <Form>
      <div className='w-100 d-flex justify-content-end mb-3'>
        <Button
          type='button'
          onClick={handleNextStep}
          className='btn btn-primary'
          color='primary'
        >
          Next
        </Button>
      </div>
      <FormGroup>
        <Label for='roleName'>Role Name</Label>
        <Input
          id='roleName'
          name='roleName'
          placeholder='Role name'
          type='text'
          onChange={handleChangeName}
          value={name}
          invalid={errorName}
        />
        <FormFeedback>This field is required</FormFeedback>
      </FormGroup>

      <FormGroup>
        <Label for='description'>Description</Label>
        <Input
          style={{ height: '200px' }}
          id='description'
          name='description'
          type='textarea'
          onChange={handleChangeDesc}
          placeholder='Description'
          value={desc}
          invalid={errorDesc}
        />
        <FormFeedback>This field must be at most 250 character.</FormFeedback>
      </FormGroup>
    </Form>
  );
};

export default Step01;
