import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import { TYPE_TABLE, SORT_TYPE } from '../../../utils';
import useRoleHooks from './hook';
import TableComponent from '../../../components/table';
import ModelAddTask from '../components/ModelAddTask';
import { useDispatch } from 'react-redux';
import { addTasks } from '../../../redux/slices/role-settings/createRole';

const taskColumns = [
  {
    dataField: 'name',
    text: 'Name of the task',
    sort: true,
  },
  {
    dataField: 'description',
    text: 'Description',
    sort: true,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    sort: false,
  },
];

const Step05 = () => {
  const dispatch = useDispatch();
  const { currentStep, handleStepChange, data } = useRoleHooks();
  const { tasks } = data;

  const [tasksData, setTaskData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [sortOrder, setSortOrder] = useState('');
  const [sortField, setSortField] = useState('');

  const [isShowModel, setIsShowModel] = useState(false);

  const [idTask, setIdTask] = useState(null);

  useEffect(() => {
    let currentData = [...tasks];
    if(sortField && sortOrder == SORT_TYPE.DESC) {
      currentData.sort((a, b) => a[sortField].localeCompare(b[sortField], undefined, { numeric: true, sensitivity: 'base' }));
    } else if(sortField && sortOrder == SORT_TYPE.ASC) {
      currentData.sort((a, b) => b[sortField].localeCompare(a[sortField], undefined, { numeric: true, sensitivity: 'base' }));
    }

    const newData = currentData.slice(
      (currentPage - 1) * sizePerPage,
      (currentPage - 1) * sizePerPage + sizePerPage
    );
    setTaskData([...newData]);
  }, [currentPage, sizePerPage, sortOrder, sortField, tasks]);

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between w-100 align-items-center mb-3'>
        <Button
          type='button'
          color='primary'
          onClick={() => handleStepChange({ step: currentStep, back: true })}
        >
          Previous
        </Button>
        <Button
          type='button'
          color='primary'
          onClick={() => handleStepChange({ step: currentStep })}
        >
          Next
        </Button>
      </div>
      <Row>
        <Col className='col-12 d-flex justify-content-end'>
          <Button
            className='me-2 mb-4'
            color='primary'
            onClick={() => setIsShowModel(true)}
          >
            <i className='mdi mdi-plus font-size-14' />
            New Task
          </Button>
        </Col>
      </Row>
      <Row className='tasks'>
        <Col className='col-12'>
          <TableComponent
            data={tasksData}
            editOption
            onEdit={(id) => {
              setIdTask(id);
              setIsShowModel(true);
            }}
            deleteOption
            typeTable={TYPE_TABLE.TASK}
            columns={taskColumns}
            setData={(array) => dispatch(addTasks(array))}
            pageNum={currentPage}
            sizePerPage={sizePerPage}
            totalSize={tasks.length}
            setPageNum={setCurrentPage}
            setPerPageSize={setSizePerPage}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
          />
        </Col>
      </Row>
      <ModelAddTask
        isShowModel={isShowModel}
        setIsShowModel={setIsShowModel}
        idTask={idTask}
        setIdTask={setIdTask}
      />
    </React.Fragment>
  );
};

export default Step05;
