import React from 'react';
import { Spinner } from 'reactstrap';
import { Button } from 'reactstrap';

const LoadingButton = ({ loading, onClick, content }) => {
  return (
    <Button
      type='button'
      color='primary'
      onClick={onClick}
      disabled={loading}
      className='d-flex align-items-center'
    >
      {loading && (
        <Spinner style={{ width: '1rem', height: '1rem', marginRight: '8px' }}>
          Loading...
        </Spinner>
      )}
      {content}
    </Button>
  );
};

export default LoadingButton;
