import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  FormGroup,
  Form,
  Label,
  Input,
  FormFeedback,
} from 'reactstrap';
import './style.scss';
import CheckBoxTreeComponent from '../../../components/checkbox-tree/index';
import useRoleHooks from '../steps/hook';
import { useDispatch } from 'react-redux';
import { addQuickActions } from '../../../redux/slices/role-settings/createRole';
import uuid from 'react-uuid';
import { toast } from 'react-toastify';
import LoadingSpinner from "../../../components/FormList/LoadingSpinner";
import { CREATED_MESSAGE, UPDATED_MESSAGE } from "../../../utils";
const CheckBoxDocument = React.lazy(() =>
  import("../../../components/client-settings/quick-actions/add-action/CheckBoxDocument")
);

function ModelAddQuickAction({
  isShowModel,
  setIsShowModel,
  idAction,
  setIdAction,
  clientId
}) {
  const [actionSelected, setActionsSelected] = useState([]);

  const [quickActionName, setQuickActionName] = useState('');
  const [errorName, setErrorName] = useState(false);
  const [errorActions, setErrorActions] = useState(false);

  const dispatch = useDispatch();
  const { authQuickActions, quickActions } = useRoleHooks();
  const { expandedData, data } = authQuickActions;

  const toggle = () => setIsShowModel(!isShowModel);

  const handleSave = () => {
    if (errorName || errorActions) return;
    if (!quickActionName) {
      setErrorName(true);
      return;
    }
    if (!actionSelected.length) {
      setErrorActions(true);
      return;
    }

    if (idAction) {
      const payload = quickActions.map((item) => {
        if (item.id === idAction) {
          return {
            ...item,
            name: quickActionName,
            company_document_ids: actionSelected,
          };
        }
        return item;
      });
      dispatch(addQuickActions([...payload]));
      setIdAction && setIdAction(null);
      toast.success(UPDATED_MESSAGE);
    } else {
      const data = {
        id: uuid(),
        name: quickActionName,
        company_document_ids: actionSelected,
      };
      dispatch(addQuickActions([...quickActions, data]));
      toast.success(CREATED_MESSAGE);
    }
    toggle();
    resetData();
  };

  const resetData = () => {
    setQuickActionName('');
    setActionsSelected([]);
  };

  useEffect(() => {
    if (idAction && isShowModel) {
      const data = quickActions.filter((val) => {
        return val.id === idAction;
      });
      if (data.length > 0) {
        setActionsSelected(data[0].company_document_ids);
        setQuickActionName(data[0].name);
      }
    } else if (!isShowModel && idAction) {
      setIdAction && setIdAction(null);
      resetData();
    }
  }, [isShowModel]);

  useEffect(() => {
    if (actionSelected.length && errorActions) {
      setErrorActions(false);
    }
  }, [actionSelected]);

  return (
    <Modal isOpen={isShowModel} toggle={toggle} className="model-qa" contentClassName='quick-action-modal-content' size="xl" style={{maxWidth: '1080px !important', width: '100%'}}>
      <ModalHeader toggle={toggle}>
        {idAction ? "Edit Quick Action" : "Add Quick Action"}
      </ModalHeader>
      <ModalBody>
        <Form inline onSubmit={handleSave}>
          <Container>
            <FormGroup className="mb-2 mr-sm-2 mb-sm-0 d-flex">
              <Label
                for="nameQuickAction"
                className="pt-2 mr-4"
                style={{ marginRight: "8px" }}
              >
                Name
              </Label>
              <div className="position-relative w-100">
                <Input
                  className="ml-2"
                  type="text"
                  name="nameQuickAction"
                  invalid={errorName}
                  value={quickActionName}
                  id="nameQuickAction"
                  placeholder="Name quick action"
                  onChange={(e) => {
                    const text = e.target.value;
                    if (errorName && text) setErrorName(false);
                    setQuickActionName(text);
                  }}
                />
                {errorName && (
                  <FormFeedback>{"This field is required"}</FormFeedback>
                )}
              </div>
            </FormGroup>
          </Container>
          {errorActions && (
            <span className="error-message">{"This field is required"}</span>
          )}
          {/* {data.length === 0 && <LoadingSpinner />} */}
          <CheckBoxDocument
            clientId={clientId}
            checkedProp={actionSelected}
            onChecked={setActionsSelected}
          />
          {/* <CheckBoxTreeComponent
            options={data}
            expandedProp={expandedData}
            checkedProp={actionSelected}
            onChecked={setActionsSelected}
          /> */}
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModelAddQuickAction;
