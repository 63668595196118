import React from "react";

const PageLayout = ({ children }) => {
  return (
    <div id="layout-wrapper">
      <div>{children}</div>
    </div>
  );
};

export default PageLayout;
