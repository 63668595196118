import { Controller } from 'stimulus';
import axios from 'axios';
import template from 'lodash.template';

export default class extends Controller {
  static targets = [
    'advisorsListTemplate',
    'advisorsListContainer',
    'sevenDays',
    'fifteenDays',
    'thirtyDays',
  ];

  connect() {
    this.appliedDaysFilter = '0-7days';
    this.appliedLastLoginFilter = 'logged_in';

    this.daysFilters = {
      '0-7days': {
        label: '7 Days',
        value: 7,
      },
      '7-15days': {
        label: '15 Days',
        value: 15,
      },
      '15-30days': {
        label: '30 Days',
        value: 30,
      },
    };
    this.lastLoginFilters = {
      logged_in: {
        value: 'Logged in',
      },
      not_logged_in: {
        value: 'Not Logged in',
      },
    };
    this.fetchAdvisorLoginStats(
      this.daysFilters[this.appliedDaysFilter].value,
      this.lastLoginFilters[this.appliedLastLoginFilter].value
    );

    this.requesting = false;
  }

  changeDaysFilter(e) {
    e.preventDefault();
    // console.log("in");
    // console.log(this.sevenDaysTarget);
    // console.log(this.fifteenDaysTarget);
    // if (this.sevenDaysTarget.checked == true) {
    //   this.appliedDaysFilter = '0-7days'
    //   this.fetchAdvisorLoginStats(this.daysFilters[this.appliedDaysFilter].value, this.lastLoginFilters[this.appliedLastLoginFilter].value)
    // }
    // else if (this.fifteenDaysTarget.checked == true) {
    //   this.appliedDaysFilter = '7-15days'
    //   this.fetchAdvisorLoginStats(this.daysFilters[this.appliedDaysFilter].value, this.lastLoginFilters[this.appliedLastLoginFilter].value)
    // }
    // else if (this.thirtyDaysTarget.checked == true) {
    //   this.appliedDaysFilter = '15-30days'
    //   this.fetchAdvisorLoginStats(this.daysFilters[this.appliedDaysFilter].value, this.lastLoginFilters[this.appliedLastLoginFilter].value)
    // }
    this.appliedDaysFilter = e.target.dataset.filter;
    this.fetchAdvisorLoginStats(
      this.daysFilters[this.appliedDaysFilter].value,
      this.lastLoginFilters[this.appliedLastLoginFilter].value
    );

    const buttons = e.target.parentElement.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('btn-primary');
      buttons[i].classList.add('btn-outline-primary');
    }
    e.target.classList.add('btn-primary');
    e.target.classList.remove('btn-outline-primary');
  }

  changeLastLoginFilter(e) {
    e.preventDefault();
    this.appliedLastLoginFilter = e.target.dataset.filter;
    console.log(this.appliedLastLoginFilter);
    this.fetchAdvisorLoginStats(
      this.daysFilters[this.appliedDaysFilter].value,
      this.lastLoginFilters[this.appliedLastLoginFilter].value
    );

    const buttons = e.target.parentElement.getElementsByTagName('button');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].classList.remove('btn-primary');
      buttons[i].classList.add('btn-outline-primary');
    }
    e.target.classList.add('btn-primary');
    e.target.classList.remove('btn-outline-primary');
  }

  fetchAdvisorLoginStats(dayFilter, lastLoginFilter) {
    this.template = template(this.advisorsListTemplateTarget.innerHTML);
    const url = `/api/v1/advisors`;
    const params = {
      page: 1,
      from_time: dayFilter,
      last_login: lastLoginFilter,
    };
    this.requesting = true;
    this.advisorsListContainerTarget.innerHTML = '';
    axios.get(url, { params }).then((response) => {
      this.requesting = false;
      response.data.map((result) => {
        this.advisorsListContainerTarget.innerHTML += this.template(result);
      });
    });
  }

  getOffsetDate(days) {
    const oneDayOffset = 24 * 60 * 60 * 1000;
    const today = new Date();
    const offsetTime = today.setTime(today.getTime() - oneDayOffset * days);
    return new Date(offsetTime);
  }
}
