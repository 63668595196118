import { Controller } from 'stimulus';
import axios from 'axios';
export default class extends Controller {
  static targets = ['sideBarPanel'];

  connect() {}

  close(e) {
    e.preventDefault();
    this.sideBarPanelTarget.style.display = 'none';
    document.getElementById('menuButton').style.display = 'block';
  }

  open() {
    document.getElementById('leftnav').style.display = 'block';
    document.getElementById('menuButton').style.display = 'none';
  }

  switchBranch(e) {
    e.preventDefault();
    console.log(e.target.dataset);
    const params = {
      id: e.target.dataset.employeeid,
      company_id: e.target.dataset.companyid,
    };
    axios
      .post(`/employees/switch_branch`, { employee: params })
      .then((response) => {
        window.location.reload();
      });
  }
}
