import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'slidingWrapper',
    'isDocumentInfo',
    'isDocumentComments',
    'openArrowIcon',
    'closeArrowIcon',
  ];

  connect() {
    this.isDocumentInfoTarget.style.display = 'none';
    this.isDocumentCommentsTarget.style.display = 'none';
    this.closeArrowIconTarget.style.display = 'none';
    this.slidingWrapperVisible = false;
    this.documentInfoVisible = false;
    this.documentCommentsVisible = false;
  }

  toggleDocumentInfo(e) {
    e.preventDefault();
    if (!this.documentInfoVisible) {
      this.openArrowIconTarget.style.display = 'none';
      this.closeArrowIconTarget.style.display = 'flex';

      this.slidingWrapperTarget.style.display = 'inline';
      this.slidingWrapperVisible = true;

      this.isDocumentInfoTarget.style.display = 'inline';
      this.documentInfoVisible = true;

      this.isDocumentCommentsTarget.style.display = 'none';
      this.documentCommentsVisible = false;
    }
  }

  toggleDocumentComments(e) {
    e.preventDefault();
    if (!this.documentCommentsVisible) {
      this.openArrowIconTarget.style.display = 'none';
      this.closeArrowIconTarget.style.display = 'flex';

      this.slidingWrapperTarget.style.display = 'inline';
      this.slidingWrapperVisible = true;

      this.isDocumentCommentsTarget.style.display = 'inline';
      this.documentCommentsVisible = true;

      this.isDocumentInfoTarget.style.display = 'none';
      this.documentInfoVisible = false;
    }
  }

  closeSlidingSidebar(e) {
    e.preventDefault();

    this.closeArrowIconTarget.style.display = 'none';
    this.openArrowIconTarget.style.display = 'flex';

    this.slidingWrapperTarget.style.display = 'none';
    this.isDocumentInfoTarget.style.display = 'none';
    this.isDocumentCommentsTarget.style.display = 'none';
    this.isDocumentSignaturesListTarget.style.display = 'none';
    this.slidingWrapperVisible = false;
    this.documentInfoVisible = false;
    this.documentCommentsVisible = false;
    this.documentSignaturesListVisible = false;
  }

  openSlidingSidebar(e) {
    e.preventDefault();
    this.openArrowIconTarget.style.display = 'none';
    this.closeArrowIconTarget.style.display = 'flex';

    this.slidingWrapperTarget.style.display = 'inline';
    this.isDocumentInfoTarget.style.display = 'inline';
    this.isDocumentCommentsTarget.style.display = 'none';
    this.isDocumentSignaturesListTarget.style.display = 'none';
    this.slidingWrapperVisible = true;
    this.documentInfoVisible = true;
    this.documentCommentsVisible = false;
    this.documentSignaturesListVisible = false;
  }
}
