import { Controller } from 'stimulus';
import { Tab } from 'bootstrap';

export default class extends Controller {
  static targets = ['allTab'];

  connect() {
    const url = document.location.toString();
    const URL = unescape(url);
    if (URL.match('#')) {
      const ele = $('.nav-tabs a[href="#' + URL.split('#')[1] + '"]')[0];
      const tab = new Tab(ele);
      tab.show();
    }
    // $('.nav-tabs a').on('shown.bs.tab', function (e) {
    //   window.location.hash = e.target.hash;
    // });
  }
}
