import React from 'react';
import isEmpty from 'lodash.isempty';
import ReactTooltip from 'react-tooltip';
const UserComment = ({ comment, date }) => {
  const tooltipInfo = date + ' at ' + comment.time;
  return (
    <li className='comment'>
      <div className='avatar'>
        {!isEmpty(comment.user.avatar_url) && (
          <div
            className='avatar-img'
            style={{
              backgroundImage: `url("${comment.user.avatar_url}")`,
            }}
          ></div>
        )}
        {isEmpty(comment.user.avatar_url) && (
          <div className={'initials'}>
            <span className={'letter'}>{comment.user.name.charAt(0)}</span>
          </div>
        )}
      </div>
      <div>
        <div className='author'>
          <div>
            <div className='d-flex'>
              <div className='text-truncate name'>{comment.user.name}</div>
              <div className='time' data-tip={tooltipInfo}>
                {comment.time}
              </div>
              <ReactTooltip
                place='top'
                type='dark'
                effect='float'
                className='tooltip'
              />
            </div>
            <div className='role'>{comment.user.job_title}</div>
          </div>
        </div>
        <div
          dangerouslySetInnerHTML={{
            __html: comment.content,
          }}
        ></div>
      </div>
    </li>
  );
};

export default UserComment;
