import React, { useState, useMemo } from 'react';
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
} from 'reactstrap';
import './style.scss';
import Select from '../../../components/select';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useParams } from 'react-router';

function ModelDeleteRole({
  isShowModel,
  setIsShowModel,
  id,
  roles,
  reload,
  setReload
}) {
  const [roleId, setRoleId] = useState(null);
  const [loading, setLoading] = useState(false);
  const {clientId} = useParams();

  const handleSave = () => {
    if (loading) return;
    setLoading(true)
    const url = `/api/v1/clients/${clientId}/custom_roles/${id}?replace_role_id=${roleId}`;
    axios
      .delete(url)
      .then((responsive) => {
        setReload(!reload)
        toast.success('Role deleted successfully!');
      })
      .catch((err) => {
        toast.error(err.response?.data?.errors[0]);
      }).finally(() => {
        setLoading(false);
        setRoleId(null);
        setIsShowModel(false);
      })
  };

  const toggle = () => setIsShowModel(!isShowModel);

  const options = useMemo(() => 
    roles.filter(r => r.id != id).map((role) => {
      return {
        id: role.id,
        label: role.name
      }
    }), [roles, id]);

  return (
    <Modal isOpen={isShowModel} toggle={toggle} className='model-delete-role'>
      <ModalHeader toggle={toggle}>Delete Role</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSave}>
          <Select
            label='Please select a new role for current users'
            options={options}
            value={roleId}
            name='change-role'
            handleChange={(e) => setRoleId(e.target.value)}
            isRequired
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={handleSave}
          disabled={!roleId || loading}
        >
          Save
        </Button>
        <Button color='secondary' onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModelDeleteRole;
