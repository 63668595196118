import React from 'react';
import isEmpty from 'lodash.isempty';
import {
  Row,
  Col,
  Card,
  Input,
  CardBody,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
import {
  BIZPORT_SERVICES,
  DELETED_MESSAGE,
  TYPE_TABLE,
  makeBadgePillColor,
} from '../../utils';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import './style.scss';
import Calendar from '../calendar';
import moment from 'moment';

const TableComponent = ({
  data,
  setData,
  pageNum,
  columns,
  setPageNum,
  sizePerPage,
  setPerPageSize,
  setSortOrder,
  setSortField,
  totalSize,
  selectedValues,
  setSelectedValues,
  typeTable,
  editOption,
  onEdit,
  deleteOption,
  onDelete,
  openOption,
  onChange
}) => {
  const { clientId } = useParams();
  const navigate = useNavigate();

  const pageOptions = {
    page: pageNum,
    sizePerPage,
    totalSize,
    custom: true,
  };

  const defaultSorted = [
    {
      dataField: 'id',
      order: 'asc',
    },
  ];

  const handleEdit = (id) => {
    switch (typeTable) {
      case TYPE_TABLE.TASK:
        onEdit && onEdit(id);
        break;
      case TYPE_TABLE.QUICK_ACTION:
        onEdit && onEdit(id);
        break;
      case TYPE_TABLE.ROLE_LIST:
        onEdit && onEdit(id);
        break;
      default:
        console.log(id);
    }
  };

  const handleOpen = (id, uuid='') => {
    switch (typeTable) {
      case TYPE_TABLE.SIGNED_DOCUMENTS:
        navigate(`/f/clients/${clientId}/documents/${uuid}`);
        break;
      default:
        console.log(id);
    }
  };

  const handleDelete = (id) => {
    if (confirm('Are you sure you want to delete?') == true) {
      if (
        TYPE_TABLE.ROLE_LIST === typeTable ||
        TYPE_TABLE.QUICK_ACTION === typeTable
      ) {
        onDelete && onDelete(id);
      } else {
        toast.success(DELETED_MESSAGE);
        const res = data.filter((item) => item.id !== id);
        setData(res);
      }
    }
  };

  const handleChangeCheckbox = (e) => {
    const value = e.target.value;
    if (e.target.checked) {
      setSelectedValues([...selectedValues, e.target.value]);
    } else {
      const data = selectedValues.filter((item) => {
        return item != value;
      });
      setSelectedValues(data);
    }
  };

  let arrayData = [];

  !isEmpty(data) &&
    data.map((item) => {
      let obj = {};

      !isEmpty(columns) &&
        columns.map((columnName) => {
          if (columnName.dataField === 'actions') {
            obj[`${columnName.dataField}`] = (
              <div className='btn-group dropstart'>
                <button
                  type='button'
                  className='btn font-size-16 btn-link text-muted mt-n2 '
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  <i className='mdi mdi-dots-horizontal'></i>
                </button>
                <ul
                  className='dropdown-menu'
                  aria-labelledby='dropdownMenuButton1'
                >
                  {editOption && (
                    <li
                      className='dropdown-item cursor-pointer'
                      onClick={() => handleEdit(item?.id)}
                    >
                      Edit
                    </li>
                  )}
                  {deleteOption && (
                    <li
                      className='dropdown-item cursor-pointer'
                      onClick={() => handleDelete(item?.id)}
                    >
                      Delete
                    </li>
                  )}
                  {openOption && (
                    <li
                      className='dropdown-item cursor-pointer'
                      onClick={() => handleOpen(item?.id || item?.uuid, item?.uuid)}
                    >
                      Open
                    </li>
                  )}
                </ul>
              </div>
            );
          } else if (columnName.dataField === 'user') {
            obj[`${columnName.dataField}`] = (
              <div className='btn-group dropstart'>
                <span
                  className='font-size-12 m-0 break-spaces text-capitalize cursor-pointer'
                  data-bs-toggle='dropdown'
                  aria-expanded='false'
                >
                  {item[columnName.dataField]}
                </span>
                {item.user_list.length !== 0 && (
                  <ul
                    className='dropdown-menu'
                    style={{ maxHeight: "85px", overflow: 'auto' }}
                    aria-labelledby='dropdownMenuButton1'
                  >
                    {item.user_list.map((value) => {
                      return (
                        <span className='dropdown-item cursor-pointer text-capitalize font-size-12 m-0 break-spaces'>
                          {value.name}
                        </span>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          } else if (columnName.dataField === 'assign') {
            obj[`${columnName.dataField}`] = (
              <div style={{ paddingLeft: '14px' }}>
                <Input
                  type='checkbox'
                  className='cursor-pointer'
                  checked={selectedValues?.includes(item?.id?.toString() || item?.uuid?.toString())}
                  value={item?.id || item?.uuid}
                  onChange={handleChangeCheckbox}
                />
              </div>
            );
          } else if (columnName.dataField === 'role_created_by') {
            obj[`${columnName.dataField}`] = (
              <span className='font-size-12 m-0 break-spaces text-capitalize'>
                {item[columnName.dataField].is_admin
                  ? BIZPORT_SERVICES
                  : item[columnName.dataField].name}
              </span>
            );
          } else if (columnName.dataField === 'dueDate') {
            if(item[columnName.dataField]) {
              var parts = item[columnName.dataField].split("/");
              var dateObject = new Date(parts[2], parts[1] - 1, parts[0]);
              obj[`${columnName.dataField}`] = (
                <Calendar
                  classCustom='task-list'
                  date={new Date(dateObject)}
                  handleChange={(d) => onChange(d, item.id)}
                />
              );
            }else {
              obj[`${columnName.dataField}`] = (
                <Calendar
                  classCustom='task-list'
                  handleChange={(d) => onChange(d, item.id)}
                />
              );
            }
          } else if (
            columnName.dataField === 'status' ||
            columnName.dataField === 'document_type'
          ) {
            obj[`${columnName.dataField}`] = (
              <span
                className={`badge rounded-pill ${makeBadgePillColor(
                  item[columnName.dataField]
                )}`}
              >
                {item[columnName.dataField]}
              </span>
            );
          } else if (
            columnName.dataField === 'created_at' ||
            columnName.dataField === 'last_updated'
          ) {
            obj[`${columnName.dataField}`] = (
              <span className='font-size-12 m-0 break-spaces'>
                {item[columnName.dataField]}
              </span>
            );
          } else {
            obj[`${columnName.dataField}`] = (
              <span className='font-size-12 m-0 break-spaces text-capitalize'>
                {item[columnName.dataField]}
              </span>
            );
          }
        });

      arrayData.push(obj);
    });

  const onTableChange = (type, { page, sizePerPage, sortOrder, sortField }) => {
    setPageNum(page);
    setPerPageSize(sizePerPage);
    setSortOrder && setSortOrder(sortOrder);
    !isEmpty(sortField) &&
      setSortField &&
      setSortField(sortField.toLowerCase());
  };

  return (
    <React.Fragment>
      <Row className='mb-3'>
        <div className='d-xl-flex'>
          <div className='w-100'>
            <Card>
              <CardBody>
                <div>
                  {!isEmpty(columns) && (
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField='id'
                      columns={columns}
                      data={arrayData}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField='id'
                          columns={columns}
                          data={arrayData}
                        >
                          {(toolkitProps) => (
                            <React.Fragment>
                              <Row>
                                <Col xl='12'>
                                  <div className='table-responsive'>
                                    <BootstrapTable
                                      remote
                                      keyField={'id'}
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      hover
                                      defaultSorted={defaultSorted}
                                      classes={
                                        'table align-middle table-nowrap'
                                      }
                                      headerWrapperClasses={'thead-light'}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      onTableChange={onTableChange}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className='align-items-md-center mt-3'>
                                <Col className='inner-custom-pagination d-flex'>
                                  <div className='d-inline'>
                                    <SizePerPageDropdownStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                  <div className='text-md-right ms-auto'>
                                    <PaginationListStandalone
                                      {...paginationProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  )}
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Row>
    </React.Fragment>
  );
};

export default TableComponent;
