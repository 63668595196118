import React, { useState, useEffect } from 'react';
import { Button } from 'reactstrap';
import isEmpty from 'lodash.isempty';
import axios from 'axios';
import { BASE_URL } from '../../../constants';
import { getClientId } from '../../utils';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

export default ({ client_id }) => {
  const clientId = client_id
    ? client_id
    : getClientId(window.location.pathname);
  const [portalLinks, setPortalLinks] = useState({
    hse: '',
    hr: '',
    rtw: '',
  });

  useEffect(() => {
    const fetchPortalLinks = async () => {
      await axios
        .get(`${BASE_URL}/api/v1/companies/${clientId}/portal_links.json`)
        .then((res) => {
          setPortalLinks(res.data);
        });
    };
    if (clientId) {
      fetchPortalLinks();
    }
  }, [clientId]);

  const portalLinkPath = (linkName) => {
    let path = null;
    path = portalLinks[`${linkName}`];
    !isEmpty(path) && window.location.replace(path);
  };

  return (
    <div>
      {clientId && (
        <ul className='navbar-nav d-flex align-items-center'>
          {!isEmpty(portalLinks[`hse`]) && (
            <li>
              <Button color='primary' onClick={() => portalLinkPath('hse')}>
                {`HSE`}
              </Button>
            </li>
          )}
          {!isEmpty(portalLinks[`hr`]) && (
            <li>
              <Button
                color='light'
                className='ms-2 me-2'
                onClick={() => portalLinkPath('hr')}
              >
                {`HR`}
              </Button>
            </li>
          )}
          {!isEmpty(portalLinks[`rtw`]) && (
            <li>
              <Button
                color='light'
                className='ms-2 me-2'
                onClick={() => portalLinkPath('rtw')}
              >
                {`RTW`}
              </Button>
            </li>
          )}
        </ul>
      )}
    </div>
  );
};
