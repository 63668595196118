import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Form,
  Label,
  Input,
  FormFeedback,
} from "reactstrap";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { addTasks } from "../../../redux/slices/role-settings/createRole";
import uuid from "react-uuid";
import { toast } from "react-toastify";
import { CREATED_MESSAGE, UPDATED_MESSAGE } from "../../../utils";

function ModelAddTask({ isShowModel, setIsShowModel, idTask, setIdTask }) {
  const [name, setName] = useState("");
  const [errorName, setErrorName] = useState(false);

  const [description, setDescription] = useState("");

  const dispatch = useDispatch();
  const dataRedux = useSelector((state) => state.createRole);
  const { tasks } = dataRedux;

  const toggle = () => setIsShowModel(!isShowModel);

  const handleSave = (event) => {
    event.preventDefault();
    if (errorName) return;
    if (!name) {
      setErrorName(true);
      return;
    }
    if (idTask) {
      const payload = tasks.map((item) => {
        if (item.id === idTask) {
          return {
            ...item,
            name,
            description,
          };
        }
        return item;
      });
      dispatch(addTasks([...payload]));
      setIdTask && setIdTask(null);
      toast.success(UPDATED_MESSAGE);
    } else {
      const data = {
        id: uuid(),
        name,
        description,
      };
      dispatch(addTasks([...tasks, data]));
      toast.success(CREATED_MESSAGE);
    }

    toggle();
    resetData();
  };

  const resetData = () => {
    setName("");
    setDescription("");
  };

  useEffect(() => {
    if (idTask && isShowModel) {
      const data = tasks.filter((val) => {
        return val.id === idTask;
      });
      if (data.length > 0) {
        setName(data[0].name);
        setDescription(data[0].description);
      }
    } else if (!isShowModel && idTask) {
      setIdTask && setIdTask(null);
      resetData();
    }
  }, [isShowModel]);

  return (
    <Modal isOpen={isShowModel} toggle={toggle} className='model-tasks'>
      <ModalHeader toggle={toggle}>{idTask ? 'Edit Task' : 'New Task'}</ModalHeader>
      <ModalBody>
        <Form onSubmit={handleSave}>
          <FormGroup>
            <Label for="taskName"> Name of the Task</Label>
            <Input
              id="taskName"
              name="taskName"
              placeholder="Enter a name for the task"
              type="text"
              value={name}
              onChange={(e) => {
                const text = e.target.value;
                if (errorName && text) setErrorName(false);
                setName(text);
              }}
              invalid={errorName}
            />
            <FormFeedback>Enter Name</FormFeedback>
          </FormGroup>

          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              style={{ height: "200px" }}
              id="description"
              name="description"
              type="textarea"
              placeholder="Enter a brief description about the task to help others know what this is about"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Save
        </Button>{" "}
        <Button color="secondary" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ModelAddTask;
