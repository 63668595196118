import React, { useEffect, useState } from 'react';
import { Button, Form } from 'reactstrap';
import useRoleHooks from './hook';
import CheckBoxTreeComponent from '../../../components/checkbox-tree/index';
import LoadingSpinner from "../../../components/FormList/LoadingSpinner";
import axios from "axios";
const CheckBoxDocument = React.lazy(() =>
  import("../../../components/client-settings/quick-actions/add-action/CheckBoxDocument")
);

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

const Step02 = () => {
  const { currentStep, handleStepChange, docxAuth, authDocuments, clientId } =
    useRoleHooks();

  const [actionSelected, setActionsSelected] = useState([]);
  const { expandedData, data } = authDocuments;

  useEffect(() => {
    if (docxAuth.length > 0) {
      setActionsSelected([...actionSelected, ...docxAuth]);
    }
  }, [docxAuth]);

  return (
    <React.Fragment>
      <div className="d-flex w-100 justify-content-between mb-3">
        <Button
          type="button"
          className="btn btn-primary"
          color="primary"
          onClick={() => handleStepChange({ step: currentStep, back: true })}
        >
          Previous
        </Button>
        <Button
          type="button"
          className="btn btn-primary"
          color="primary"
          onClick={() =>
            handleStepChange({ step: currentStep, payload: actionSelected })
          }
        >
          Next
        </Button>
      </div>
      <p class="text-[14px]">Documents you want your user to access and modify</p>
      <Form
        inline
        className="d-flex justify-content-center align-items-center flex-column"
      >
        {/* {data.length === 0 && <LoadingSpinner />} */}
        <CheckBoxDocument
          clientId={clientId}
          checkedProp={actionSelected}
          onChecked={setActionsSelected}
        />
        {/* <CheckBoxTreeComponent
          options={data}
          expandedProp={expandedData}
          checkedProp={actionSelected}
          onChecked={setActionsSelected}
        /> */}
      </Form>
    </React.Fragment>
  );
};

export default Step02;
