import { Controller } from 'stimulus';
import axios from 'axios';
import { Tab } from 'bootstrap';

export default class extends Controller {
  static targets = ['modal', 'allTab'];

  connect() {
    const url = document.location.toString();
    const URL = unescape(url);
    if (URL.match('#')) {
      const ele = $('.nav-tabs a[href="#' + URL.split('#')[1] + '"]')[0];
      const tab = new Tab(ele);
      tab.show();
    }
    // $('.nav-tabs a').on('shown.bs.tab', function (e) {
    //   // const url = window.location.href.replace(/tab=.*/, `tab=${e.target.hash}`);
    //   window.history.replaceState(window.history.state, '', url);
    // });
  }

  sendReminder(e) {
    e.preventDefault();
    const element = e.target;
    const employeeAssignmentId = element.dataset.employeeassignmentid;
    const url = `/api/v1/employee_assignments/${employeeAssignmentId}/reminder`;
    axios.get(url).then((response) => {
      element.parentElement.parentElement.innerHTML =
        '<span style="font-size: 13px; color: #28A745;"> &#10004; reminder sent</span>';
    });
  }

  showMailerModal(e) {
    e.preventDefault();
    const element = e.target.parentElement;
    const employeeAssignmentId = element.dataset.employeeassignmentid;
    document.getElementById(
      'employee_assignment_id'
    ).value = employeeAssignmentId;
  }
}
