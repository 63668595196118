import React from 'react';
import { useParams, matchPath, useLocation } from 'react-router';
import { Card } from 'reactstrap';

const FileLeftBar = ({ quickActionRole }) => {
  const { clientId } = useParams();
  const { pathname } = useLocation();
  return (
    <div>
      <Card className='w-100 me-md-2 sticky-container'>
        <div className='d-flex flex-column h-100'>
          <div className='mb-4 mt-4'>
            <ul className='list-unstyled categories-list m-0 p-0'>
              <li
                className={`${
                  matchPath(
                    pathname,
                    `/f/clients/${clientId}/settings/notifications`
                  ) && 'bg-light'
                }`}
              >
                <a
                  href={`/f/clients/${clientId}/settings/notifications`}
                  className='text-body d-flex align-items-center'
                >
                  <i className='mdi mdi-bell text-muted font-size-16 me-2'></i>
                  <span className='me-auto'>Notifications</span>
                </a>
              </li>
              <li
                className={`${
                  matchPath(
                    pathname,
                    `/f/clients/${clientId}/trash/documents`
                  ) && 'bg-light'
                }`}
              >
                <a
                  href={`/f/clients/${clientId}/trash/documents`}
                  className='text-body d-flex align-items-center'
                >
                  <i className='mdi mdi-trash-can text-muted font-size-16 me-2'></i>
                  <span className='me-auto'>Archives</span>
                </a>
              </li>
              <li
                className={`${
                  matchPath(
                    pathname,
                    `/f/clients/${clientId}/settings/roles`
                  ) && 'bg-light'
                }`}
              >
                <a
                  href={`/f/clients/${clientId}/settings/roles`}
                  className='text-body d-flex align-items-center'
                >
                  <i className='mdi mdi-account text-muted font-size-16 me-2'></i>
                  <span className='me-auto'>Roles</span>
                </a>
              </li>

              {
                quickActionRole && 
                <li
                  className={`${
                    matchPath(
                      pathname,
                      `/f/clients/${clientId}/settings/quick-actions`
                    ) && 'bg-light'
                  }`}
                >
                  <a
                    href={`/f/clients/${clientId}/settings/quick-actions`}
                    className='text-body d-flex align-items-center'
                  >
                    <i className="mdi mdi-file-document text-muted font-size-16 me-2"></i>
                    <span className='me-auto'>Quick Actions</span>
                  </a>
                </li>
              }
            </ul>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default FileLeftBar;
