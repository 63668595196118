import { createSlice } from '@reduxjs/toolkit';

export const createRole = createSlice({
  name: 'createRole',
  initialState: {
    details: null,
    docxAuth: [],
    signedDocs: [],
    quickActions: [],
    tasks: [],
    permission: [],
    branches: []
  },
  reducers: {
    addRoleDetails: (state, action) => {
      state.details = { ...action.payload };
      return state;
    },
    addDocxAuthorization: (state, action) => {
      state.docxAuth = [...action.payload];
      return state;
    },
    addSignedDocuments: (state, action) => {
      state.signedDocs = [...action.payload];
      return state;
    },
    addQuickActions: (state, action) => {
      state.quickActions = [...action.payload];
      return state;
    },
    addTasks: (state, action) => {
      state.tasks = [...action.payload];
      return state;
    },
    addBranches: (state, action) => {
      state.branches = [...action.payload]
      return state;
    },
    updateRole: (state, action) => {
      const { details, docxAuth, quickActions, tasks, permission, signedDocs, branches } = action.payload;
      state.details = { ...details };
      state.docxAuth = [...docxAuth];
      state.quickActions = [...quickActions];
      state.tasks = [...tasks];
      state.permission = [...permission];
      state.signedDocs = [...signedDocs];
      state.branches = [...branches]
      return state;
    },
    updatePermissions: (state, action) => {
      state.permission = [...action.payload];
      return state;
    },
    deleteRole: (state, action) => {
      return {
        details: null,
        docxAuth: [],
        signedDocs: [],
        quickActions: [],
        tasks: [],
        permission: [],
        branches: []
      };
    },
  },
});

export const {
  addRoleDetails,
  addDocxAuthorization,
  addQuickActions,
  addTasks,
  addSignedDocuments,
  addBranches,
  deleteRole,
  updateRole,
  updatePermissions,
} = createRole.actions;

export default createRole.reducer;
