import { createSlice } from '@reduxjs/toolkit';
import { stepList } from '../../../utils';

export const roleStepSlice = createSlice({
  name: 'roleStepSlice',
  initialState: stepList.step01,
  reducers: {
    updateRoleStep: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateRoleStep } = roleStepSlice.actions;

export default roleStepSlice.reducer;
