import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['companyId', 'searchBox'];

  connect() {
    this.params = {};
    this.params['query'] = null;
    this.url = this.companyIdTarget.dataset.url;
    const buttons = document.getElementsByClassName('transfer-employee-link');
    for (let i = 0; i < buttons.length; i++) {
      buttons[i].addEventListener('click', function (e) {
        document.getElementById('employee_id').value = e.target.dataset.employeeid;
        document.getElementsByClassName('employee-name')[0].innerHTML = e.target.dataset.employeename;
      })
    }
  }

  submitSearch(e) {
    e.preventDefault();
    const query = this.searchBoxTarget.value.trim();
    if (query.length > 0 && this.params['query'] != query) {
      this.params['query'] = query;
      this.fetchEmployees();
    }
  }

  changeSearchInput(e) {
    if (e.which == 13) {
      const query = this.searchBoxTarget.value.trim();
      if (query.length > 0 && this.params['query'] != query) {
        this.params['query'] = query;
        this.fetchEmployees();
      }
    } else if (e.which == 8) {
      const query = this.searchBoxTarget.value.trim();
      if (query.length == 0) {
        this.params['query'] = null;
        this.fetchEmployees();
      }
    }
  }

  fetchEmployees() {
    let url = this.url;
    if (this.params.query) {
      url += `?q=${this.params.query}`;
    }
    window.location = url;
  }
}
