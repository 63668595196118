import React, { Suspense, useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import axios from "axios";
import isEmpty from "lodash.isempty";
import {uniq} from 'lodash'
import {
  Container,
  Col,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { DOCUMENT_TYPES, DOCUMENT_STATUSES } from "../../utils";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { BASE_URL } from "../../../constants";
import LoadingSpinner from "../../components/FormList/LoadingSpinner";
import PageLayout from "../../components/PageLayout";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { LoadingButton } from "../../components/buttons";
import { toast } from 'react-toastify';

const DocumentsList = React.lazy(() => import("./DocumentsList"));

const DocumentAuthorizations = ({ clientId, userId }) => {
  const animatedComponents = makeAnimated();
  const [requesting, setRequesting] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalPages, setTotalPages] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortField, setSortField] = useState("label");
  const [documentList, setDocumentList] = useState([]);
  const [showFilters, setShowFilters] = useState(false);
  const [documentLabelList, setDocumentLabelList] = useState([]);
  const [labelValues, setLabelValues] = useState([]);
  const [search, setSearch] = useState("");
  const [searchFlag, setSearchFlag] = useState("");
  const [typingTimeout, setTypingTimeout] = useState(0);
  const [statusValues, setStatusValues] = useState([])
  const [typeValues, setTypeValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [documentIds, setDocumentIds] = useState([]);
  const [initAllowAccessAll, setInitAllowAccessAll] = useState(false)
  const [allowAccessAll, setAllowAccessAll] = useState(false)
  const [filters, setFilters] = useState({
    fileTypes: [],
    statuses: [],
    labels: [],
  });

  const searchInputRef = useRef();

  useEffect(() => {
    setRequesting(true);

    let queryParams = {
      query: search,
      sort_order: sortOrder,
      sort_field: sortField,
      page: pageNum,
      per_page: sizePerPage,
      client_id: clientId,
    };

    if (!isEmpty(filters.statuses)) {
      queryParams["statuses"] = filters.statuses;
    }
    if (!isEmpty(filters.fileTypes)) {
      queryParams["file_types"] = filters.fileTypes;
    }
    if (!isEmpty(filters.labels)) {
      queryParams["labels"] = filters.labels;
    }

    let queryParamsString = new URLSearchParams(queryParams).toString();

    const fetchDocumentList = async () => {
      const url = `${BASE_URL}/users/${userId}/doc_authorizations.json?${queryParamsString}`;

      await axios.get(url).then((res) => {
        const documents = res.data.documents.map((doc, index) => {
          if (documentIds.length === 0) {
            return { ...doc, index: index };
          } else {
            return {
              ...doc,
              index: index,
              doc_authorization: documentIds.includes(doc.id) || allowAccessAll,
            };
          }
        });
        setInitAllowAccessAll(res.data.access_to_all_templates)
        setDocumentList(documents);
        setTotalPages(res.data.total_pages);
        setRequesting(false);
        if (documentIds.length === 0) {
          setDocumentIds(res.data.document_ids);
        }
      });
    };

    const fetchDocumentLabelsList = async () => {
      const url = `${BASE_URL}/api/v1/document_labels.json`;
      await axios.get(url).then((res) => {
        let labelOptions = [];
        res.data.map((code) => {
          labelOptions.push({ value: code, label: code });
        }),
          setDocumentLabelList(labelOptions);
      });
    };

    fetchDocumentList();
    isEmpty(documentLabelList) && fetchDocumentLabelsList();
  }, [
    pageNum,
    sizePerPage,
    sortField,
    sortOrder,
    filters,
    searchFlag
  ]);

  useEffect(() => {
    if(initAllowAccessAll !== allowAccessAll) {
      setAllowAccessAll(initAllowAccessAll)
    }
  }, [initAllowAccessAll])

  const handleSubmit = async () => {
    if (loading) return;
    setLoading(true);
    let url = `${BASE_URL}/users/${userId}/doc_authorizations.json`;
    const params = {
      document_ids: documentIds,
      client_id: clientId,
      access_to_all_templates: allowAccessAll
    };
    axios.post(url, params).then((res) => {
      toast.success("Authorizations updated successfully");
    })
    .catch((error) => {
      toast.error(err.response?.data?.errors);
    })
    .finally(() => setLoading(false));
  };

  const handleChangeAllowAccessAll = (e) => {
    const value = e.target.checked;
    if(value) {
      const updateDocList = documentList.map(obj => {
        obj.doc_authorization = value;
        return obj;
      });
      setDocumentList(updateDocList);

      let updatedDocumentIds = [...documentIds];
      updateDocList.forEach(obj => updatedDocumentIds.push(obj.id));
      setDocumentIds([...uniq(updatedDocumentIds)]);
    }
    setAllowAccessAll(value)
  }

  return (
    <section className="page-content pt-0">
      <Container fluid>
        <div className='checkbox-wrapper d-flex align-items-center mt-5'>
          <label className="mb-0 me-2 fw-bold fs-4">Allow users to access all Templates</label>
          <input
            type='checkbox'
            className='form-check-input check-pointer fs-4'
            id='allowToAccessAllTemplate'
            checked={allowAccessAll}
            onChange={(e) => handleChangeAllowAccessAll(e)}
          />
        </div>
        <Col className="col-12">
          <div className="page-title-box d-flex align-items-center justify-content-end">
            <div className="page-title-right">
              <div className="d-flex align-items-center">
                <input
                  ref={searchInputRef}
                  className="form-control search-field"
                  type="search"
                  onChange={(e) => {
                    setSearch(e.target.value);
                    if (typingTimeout) {
                      clearTimeout(typingTimeout);
                    }
                    setTypingTimeout(
                      setTimeout(() => {
                        setSearchFlag(search);
                      }, 1000)
                    );
                  }}
                  placeholder="Search Document"
                />
                <div
                  className="position-relative cursor-pointer"
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}
                >
                  <i className="mdi mdi-24px mdi-filter-variant m-0 text-muted h5" />
                  {(!isEmpty(filters.labels) ||
                    !isEmpty(filters.statuses) ||
                    !isEmpty(filters.fileTypes)) && (
                    <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-1">
                      <span className="visually-hidden"></span>
                    </span>
                  )}
                </div>
              </div>
              {showFilters && (
                <div>
                  <div
                    className="overlay"
                    onClick={() => {
                      setShowFilters(!showFilters);
                    }}
                  />
                  <div className="filter-box">
                    <div className="filter-container">
                      <h5>Filters</h5>
                      <div className="filter">
                        <label className="filter-label">Types</label>
                        <div className="filter-input">
                          <Select
                            components={animatedComponents}
                            isMulti
                            defaultValue={typeValues}
                            onChange={(values) => {
                              let types = [];
                              values.map((value) => {
                                types.push(value.value);
                              });
                              setFilters({
                                ...filters,
                                fileTypes: types,
                              });
                              setTypeValues(values);
                            }}
                            options={DOCUMENT_TYPES}
                          />
                        </div>
                      </div>
                      <div className="filter">
                        <label className="filter-label">Statuses</label>
                        <div className="filter-input">
                          <Select
                            components={animatedComponents}
                            isMulti
                            defaultValue={statusValues}
                            onChange={(values) => {
                              let statuses = [];
                              values.map((value) => {
                                statuses.push(value.value);
                              });
                              setFilters({
                                ...filters,
                                statuses: statuses,
                              });
                              setStatusValues(values);
                            }}
                            options={DOCUMENT_STATUSES}
                          />
                        </div>
                      </div>
                      <div className="filter">
                        <label className="filter-label">Codes</label>
                        <div className="filter-input">
                          <Select
                            components={animatedComponents}
                            isMulti
                            defaultValue={labelValues}
                            onChange={(values) => {
                              let codes = [];
                              values.map((value) => {
                                codes.push(value.value);
                              });
                              setFilters({ ...filters, labels: codes });
                              setLabelValues(values);
                            }}
                            options={documentLabelList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
        {!requesting && (
          <Suspense fallback={<div>Loading...</div>}>
            <DocumentsList
              documentList={documentList}
              setDocumentList={setDocumentList}
              sortField={sortField}
              setSortField={setSortField}
              sortOrder={sortOrder}
              setSortOrder={setSortOrder}
              pageNum={pageNum}
              setPageNum={setPageNum}
              sizePerPage={sizePerPage}
              setPerPageSize={setSizePerPage}
              totalSize={totalPages * sizePerPage}
              documentIds={documentIds}
              setDocumentIds={setDocumentIds}
              allowAccessAll={allowAccessAll}
              setAllowAccessAll={setAllowAccessAll}
            />
          </Suspense>
        )}
        {requesting && <LoadingSpinner />}
        {!requesting && (
          <div className="d-flex align-items-center justify-content-end">
            <LoadingButton
              content="Save"
              loading={loading}
              onClick={handleSubmit}
            />
          </div>
        )}
      </Container>
    </section>
  );
};

export default DocumentAuthorizations;
