import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['notify','userProfile', 'documentStatus'];

  connect() {}

 openUserList(e) {
    e.preventDefault();
    document.getElementById("user-action").style.display = "block";
    var element = this.userProfileTarget
    element.classList.toggle("user-display")
    element.classList.toggle("user-displaya")
    document.getElementById("overlay").style.display = "block";
  }

  openNotifications(e) {
    e.preventDefault();
    document.getElementById("userNotifications").style.display = "block";
    var element = this.notifyTarget
    element.classList.toggle("user-list")
    document.getElementById("overlay").style.display = "block";
  }

  closeOverlay() {
    document.getElementById("overlay").style.display = "none";
    document.getElementById("user-action").style.display = "none";
    document.getElementById("userNotifications").style.display = "none";
    document.getElementById("status-list").style.display = "none";
  }

  openStatusList(e) {
    e.preventDefault();
    document.getElementById("status-list").style.display = "block";
    document.getElementById("overlay").style.display = "block";
  }
}