import { Controller } from 'stimulus';
import axios from 'axios';

export default class extends Controller {
  static targets = [];

  connect() {}

  openNotification(e) {
    e.preventDefault();
    const element = e.target;
    const id = element.dataset.id;
    const target_id = element.dataset.targetid;
    const target_type = element.dataset.targettype;
    const url = `/users/${target_id}/notifications/${id}/open`;
    try {
      axios.put(url, { target_type, target_id, id }).then((response) => {
        console.log(response);
      });
    } catch (e) {
      console.log(e);
    }
  }
}
