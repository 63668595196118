import { Controller } from 'stimulus';
import axios from 'axios';
import ejs from 'ejs';
import template from 'lodash.template';

export default class extends Controller {
  static targets = [
    'selectVictoriaDocuments',
    'selectOtherDocuments',
    'delete',
    'upload',
    'deleteButtonTemplate',
    'documentStatusTemplate',
    'folderButtonTemplate',
    'documentTemplate',
    'closeSearchBoxTemplate',
  ];

  connect() {
    this.selectVictoriaDocumentsTarget.style.display = 'none';
    this.selectOtherDocumentsTarget.style.display = 'none';
    this.docStatusFormVisible = false;
    this.docDateFormVisible = false;
    this.templateSearchVisible = false;
    this.page_no = 1;

    this.template1 = ejs.compile(
      this.deleteButtonTemplateTarget.innerHTML
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    );

    this.statusTemplate = ejs.compile(
      this.documentStatusTemplateTarget.innerHTML
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    );

    this.folderTemplate = ejs.compile(
      this.folderButtonTemplateTarget.innerHTML
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    );

    this.documentTemplate = ejs.compile(
      this.documentTemplateTarget.innerHTML
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    );

    this.closeSearchBox = ejs.compile(
      this.closeSearchBoxTemplateTarget.innerHTML
        .replace(/&lt;/g, '<')
        .replace(/&gt;/g, '>')
    );
  }

  radioBtnChange(e) {
    let radio = e.target;
    if (radio.value == 'victoria') {
      this.selectOtherDocumentsTarget.style.display = 'none';
      this.selectVictoriaDocumentsTarget.style.display = 'block';
    } else if (radio.value == 'other') {
      this.selectVictoriaDocumentsTarget.style.display = 'none';
      this.selectOtherDocumentsTarget.style.display = 'block';
    }
    this.clearCheckBoxes();
  }

  clearCheckBoxes() {
    parent = document.getElementById('step-5');
    if (parent) {
      const allItems = [].slice.call(
        parent.querySelectorAll("input[type='checkbox']")
      );
      allItems.forEach((item) => (item.checked = false));
    }
  }

  uploadDoc(e) {
    e.preventDefault();
    const url = `/api/v1/company_documents`;
    const doc_template_id = e.target.dataset.docId;
    const company_id = e.target.dataset.companyId;
    const params = {
      doc_id: doc_template_id,
      company_id: company_id,
    };
    const actionsElement = e.target.closest('.actions');
    this.removeUploadButton(actionsElement);
    this.showSpinner(actionsElement);
    axios.post(url, params).then((response) => {
      this.hideSpinner(actionsElement);
      actionsElement.innerHTML = this.template1({
        company_doc_id: response.data.company_document_id,
        company_id: response.data.company_id,
        company_document: response.data.company_document,
        company_document_exist: true
      });
    });
  }

  deleteDoc(e) {
    e.preventDefault();
    if (confirm('Are you sure to delete company document?')) {
      const document_id = e.target.dataset.companyDocId;
      const company_id = e.target.dataset.companyId;
      const url = `/api/v1/company_documents/${document_id}`;
      const params = {
        company_id: company_id,
      };
      e.target.closest('li').lastChild.remove();
      const actionsElement = e.target.closest('.actions');
      this.removeDeleteButton(actionsElement);
      this.showSpinner(actionsElement);
      axios.delete(url, { params }).then((response) => {
        this.hideSpinner(actionsElement);

        actionsElement.innerHTML = this.template1({
          doc_id: response.data.doc_template_id,
          company_id: response.data.company_id,
          company_document_exist: false
        });
      });
    }
  }

  uploadFolder(e) {
    e.preventDefault();
    const url = `/api/v1/folders`;
    const folder_id = e.target.dataset.folderId;
    const company_id = e.target.dataset.companyId;
    const params = {
      folder_id: folder_id,
      company_id: company_id,
    };
    const actionsElement = e.target.closest('.actions');
    this.removeUploadButton(actionsElement);
    this.showSpinner(actionsElement);

    axios.post(url, params).then((response) => {
      this.hideSpinner(actionsElement);
      actionsElement.innerHTML = this.folderTemplate({
      });
    });
  }

  statusForm(e) {
    e.preventDefault();
    const element = e.target;
    if (!this.docStatusFormVisible) {
      element.closest('.doc-status').style.display = 'none';
      element.parentElement.parentElement.parentElement.parentElement.getElementsByClassName(
        'choose-status-form'
      )[0].style.display = 'block';
      element.closest('.choose-status-form');
      this.docStatusFormVisible = true;
    } else {
      this.docStatusFormVisible = false;
    }
  }

  saveStatus(e) {
    e.preventDefault();
    const status = e.target.parentElement.querySelector('select').value;
    const url = `/api/v1/company_documents/${e.target.dataset.documentId}`;
    const params = {
      status: status,
    };

    const actionsElement = e.target.closest('.handle');
    axios.put(url, params).then((response) => {
      actionsElement.innerHTML = this.statusTemplate({
        company_document: response.data.company_document,
      });
    });
  }

  showSpinner(element) {
    const spinner = element.querySelector('.spinner-border');
    spinner.classList.add('d-block');
    spinner.classList.remove('d-none');
  }

  hideSpinner(element) {
    const spinner = element.querySelector('.spinner-border');
    spinner.classList.add('d-none');
    spinner.classList.remove('d-block');
  }

  removeUploadButton(element) {
    element.querySelector('.upload-btn').remove();
  }

  removeDeleteButton(element) {
    element.querySelector('.delete-btn').remove();
  }

  removeCheckButton(element) {
    element.querySelector('.delete-btn').remove();
  }

  templateSearch(e) {
    e.preventDefault();
    const element = e.target;
    if (!this.templateSearchVisible) {
      element.closest('.search-icon').style.display = 'none';
      element.parentElement.parentElement.parentElement.parentElement.getElementsByClassName(
        'template-search'
      )[0].style.display = 'block';
      element.closest('.template-search');
      this.templateSearchVisible = true;
    } else {
      this.templateSearchVisible = false;
    }
  }

  submitSearch(e) {
    e.preventDefault();
    const query = e.target.parentElement.previousElementSibling.firstElementChild.value;
    const folder_id = e.target.parentElement.dataset.folderId;
    const company_id = e.target.parentElement.dataset.companyId;
    let url = "/api/v1/doc_templates"
    const params = {
      folder_id: folder_id,
      company_id: company_id,
      query: query,
    };
    e.target.closest('li').querySelector("ul").innerHTML = ''

    axios.get(url, { params }).then((response) => {
      response.data.map((result) => {
        e.target.closest('li').querySelector("ul").innerHTML += this.documentTemplate({
          company_document: result.company_document,
          doc_template: result.object,
          company_id: company_id,
        });
      });
    });
  }

  closeSearch(e) {
    e.target.closest('.template-search').innerHTML = this.closeSearchBox({
      company_id: e.target.dataset.companyId,
      folder_id: e.target.dataset.folderId,
    });
  }

  loadMore(e) {
    e.preventDefault();
    this.page_no = this.page_no + 1;
    const folder_id = e.target.parentElement.dataset.folderId;
    const company_id = e.target.parentElement.dataset.companyId;
    let url = "/api/v1/doc_templates"
    const params = {
      folder_id: folder_id,
      company_id: company_id,
      page: this.page_no,
    };

    axios.get(url, { params }).then((response) => {
      if (response.data.length < 10) {
        e.target.parentElement.parentElement.style.display = 'none'
      }
      response.data.map((result) => {
        e.target.closest("li").querySelector("ul").innerHTML += this.documentTemplate({
          company_document: result.company_document,
          doc_template: result.object,
          company_id: company_id,
        });
      });
    });
  }
}
