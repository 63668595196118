import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['docSubmissionId', 'docSubmissionIdInput'];

  connect() {
    this.docStatusFormVisible = false;
    this.docDateFormVisible = false;
  }

  toggleDocStatusForum(e) {
    e.preventDefault();
    const element = e.target;
    if (!this.docStatusFormVisible) {
      element.closest('.doc-status').style.display = 'none';
      element.parentElement.parentElement.parentElement.parentElement.getElementsByClassName(
        'choose-status-form'
      )[0].style.display = 'block';
      element.closest('.choose-status-form');
      this.docStatusFormVisible = true;
    } else {
      this.docStatusFormVisible = false;
    }
  }

  setDueDateForum(e) {
    e.preventDefault();
    const element = e.target;
    if (!this.docDateFormVisible) {
      element.closest('.doc-date').style.display = 'none';
      element.parentElement.parentElement.parentElement.parentElement.getElementsByClassName(
        'choose-date-form'
      )[0].style.display = 'block';
      element.closest('.choose-date-form');
      this.docDateFormVisible = true;
    } else {
      this.docDateFormVisible = false;
    }
  }
}
