import React from 'react';
import { FormGroup, Label, FormFeedback } from 'reactstrap';
import DatePicker from 'react-datepicker';
import range from 'lodash/range';
import { getMonth, getYear } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
const years = range(1900, getYear(new Date()) + 1, 1);
const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const Calendar = ({
  label,
  errorMessage,
  name,
  handleChange,
  isRequired,
  date,
  maxDate,
  isCustomHeader,
  classCustom,
}) => {
  return (
    <FormGroup>
      <Label>
        {label}
        {isRequired && <span className='text-danger'>*</span>}
      </Label>
      {isCustomHeader ? (
        <DatePicker
          selected={date}
          onChange={(d) => handleChange(d)}
          className='form-control p-2'
          maxDate={maxDate}
          dateFormat='dd/MM/yyyy'
          name={name}
          renderCustomHeader={({ date, changeYear, changeMonth }) => (
            <div
              style={{
                margin: 10,
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {years.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
        />
      ) : (
        <DatePicker
          selected={date}
          onChange={(d) => handleChange(d)}
          className={`form-control p-2 ${classCustom}`}
          maxDate={maxDate}
          dateFormat='dd/MM/yyyy'
          name={name}
        />
      )}
      <FormFeedback>{errorMessage}</FormFeedback>
    </FormGroup>
  );
};

export default Calendar;
