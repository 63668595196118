import React from "react";
import { Media } from "reactstrap";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const SkeletonLoading = () => {
  return (
    <ul className="list-unstyled chat-list mt-4">
      <li className="mb-4">
        <Media>
          <div className="align-self-center me-2">
            <div className="d-inline-block">
              <div className="avatar-xs me-4">
                <Skeleton width={40} height={40} circle={true} />
              </div>
            </div>
          </div>
          <Media className="overflow-hidden" body>
            <Skeleton width={120} height={10} />

            <Skeleton width={80} height={10} />
          </Media>
          <div className="font-size-11">
            <Skeleton width={25} height={10} />
          </div>
        </Media>
      </li>
      <li>
        <Skeleton height={10} count={2} />
      </li>
    </ul>
  );
};

export default SkeletonLoading;
