import React from "react";
import CheckboxTree from "react-checkbox-tree";
import "./style.scss";

function CheckBoxTreeComponent({ options, expandedProp, checkedProp, onChecked }) {
  const handleCheck = (value) => {
    if(!onChecked) return;
    onChecked(value);
  };

  if (!options) return null;

  return (
    <section className="w-100 d-flex mt-4 mb-4 checkbox-tree">
      <CheckboxTree
        checked={checkedProp}
        expanded={expandedProp}
        nodes={options}
        onCheck={handleCheck}
      />
    </section>
  );
}

export default CheckBoxTreeComponent;
