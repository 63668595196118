import React from 'react';
import { Container } from 'reactstrap';
import { useParams, matchPath, useLocation } from 'react-router';
import RoleList from './components/RoleList';

const ClientRoleSettings = () => {
  const { clientId } = useParams();
  let { pathname } = useLocation();

  return (
    <React.Fragment>
      <section id='page-topbar'>
        <Container fluid>
          <div className='navbar-header'>
            <nav aria-label='breadcrumb'>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item' aria-current='page'>
                  <a href={`/clients/${clientId}`}>Dashboard</a>
                </li>
                {matchPath(pathname, `/f/clients/${clientId}/settings`) ? (
                  <li className='breadcrumb-item active' aria-current='page'>
                    Settings
                  </li>
                ) : (
                  <li className='breadcrumb-item' aria-current='page'>
                    <a href={`/f/clients/${clientId}/settings`}>Settings</a>
                  </li>
                )}
                {matchPath(
                  pathname,
                  `/f/clients/${clientId}/settings/roles`
                ) && (
                  <li className='breadcrumb-item active' aria-current='page'>
                    Roles
                  </li>
                )}
              </ol>
            </nav>
          </div>
        </Container>
      </section>
      <RoleList />
    </React.Fragment>
  );
};

export default ClientRoleSettings;
