import { createSlice } from '@reduxjs/toolkit';
import { stepList } from '../../../utils';

export const stepSlice = createSlice({
  name: 'stepSlice',
  initialState: stepList.step01,
  reducers: {
    updateStep: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateStep } = stepSlice.actions;

export default stepSlice.reducer;
