import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['searchBox'];

  connect() {
    this.params = {};
    this.params['query'] = null;
  }

  submitSearch(e) {
    e.preventDefault();
    const query = this.searchBoxTarget.value.trim();
    if (query.length > 0 && this.params['query'] != query) {
      this.params['query'] = query;
      this.fetchAdvisors();
    }
  }

  changeSearchInput(e) {
    if (e.which == 13) {
      const query = this.searchBoxTarget.value.trim();
      if (query.length > 0 && this.params['query'] != query) {
        this.params['query'] = query;
        this.fetchAdvisors();
      }
    } else if (e.which == 8) {
      const query = this.searchBoxTarget.value.trim();
      if (query.length == 0) {
        this.params['query'] = null;
        this.fetchAdvisors();
      }
    }
  }

  fetchAdvisors() {
    let url = `/advisors`;
    if (this.params.query) {
      url += `?q=${this.params.query}`;
    }
    window.location = url;
  }
}
