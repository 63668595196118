import { useDispatch, useSelector } from 'react-redux';
import {
  addDocxAuthorization,
  addRoleDetails,
  addSignedDocuments,
} from '../../../redux/slices/role-settings/createRole';
import { stepList } from '../../../utils';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { updateRoleStep } from '../../../redux/slices/role-settings/roleStep';
import { useState } from 'react';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

export function updateIdsToValues(data) {
  const tmpData = [...data];
  const res = tmpData.map((item) => {
    if (item.hasOwnProperty('uuid')) {
      item.value = item.uuid;
    }

    if (item?.children?.length > 0) {
      updateIdsToValues(item.children);
    }
    return item;
  });

  return res;
}

export function getColumnValues(data, column, array) {
  let tmpData = [...data];

  const res = tmpData.map((item) => {
    if (item?.children?.length > 0) {
      getColumnValues(item.children, column, array);
    }
    return item[column];
  });
  array?.push(res);
  return array;
}

export const sortDocuments = (array) => {
  const data = [...array];
  data.sort((a, b) =>
    a.label.localeCompare(b.label, undefined, {
      numeric: true,
      sensitivity: 'base',
    })
  );

  function sortObjects(obj) {
    if (obj.children) {
      obj.children.sort((a, b) =>
        a.label.localeCompare(b.label, undefined, {
          numeric: true,
          sensitivity: 'base',
        })
      );
      obj.children.forEach(sortObjects);
    }
  }

  data.forEach(sortObjects);

  return data;
};

const useRoleHooks = () => {
  const { clientId, roleId } = useParams();
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [loading, setLoading] = useState(false);
  const currentStep = useSelector((state) => state.roleStep);
  const data = useSelector((state) => state.createRole);
  const authDocuments = useSelector((state) => state.authDocuments);
  const authQuickActions = useSelector((state) => state.authQuickActions);

  const { details, docxAuth, permission, quickActions, signedDocs, tasks, branches } = data;

  const handleStepChange = ({ step, back, payload }) => {
    if (back) {
      dispatch(updateRoleStep(step - 1));
      return;
    }

    let status = false;
    if (stepList.step01 === step && payload) {
      dispatch(addRoleDetails(payload));
      status = true;
    } else if (stepList.step02 === step && payload) {
      dispatch(addDocxAuthorization(payload));
      status = true;
    } else if (stepList.step03 === step) {
      dispatch(addSignedDocuments(payload));
      status = true;
    } else if (stepList.step04 === step || stepList.step05 === step) {
      status = true;
    }

    if (status) {
      const newStep = currentStep + 1;
      dispatch(updateRoleStep(newStep));
    }
  };

  const handleSave = () => {
    if(loading) return;
    setLoading(true);
    const quickActionsData = quickActions.map((element) => {
      if (typeof element.id == "string") {
        return {
          name: element.name,
          company_document_ids: element.company_document_ids,
        };
      }
      return element;
    });

    const dataParams = {
      name: details.name,
      description: details.desc,
      signed_document_ids: signedDocs,
      authorized_document_ids: docxAuth,
      custom_role_quick_actions_attributes: quickActionsData,
      custom_role_tasks_attributes: tasks,
      permissions: permission,
      branch_ids: branches.map(b => b.id)
    };

    if (!roleId) {
      const url = `/api/v1/clients/${clientId}/custom_roles.json?`;
      axios
        .post(url, dataParams)
        .then((res) => {
          navigator(`/f/clients/${clientId}/settings/roles`);
          toast.success('Role created successfully!');
        })
        .catch((err) => {
          toast.error(err.response?.data?.errors[0]);
           dispatch(updateRoleStep(stepList.step01));
        }).finally(() => {setLoading(false)});
    } else {
      const url = `/api/v1/clients/${clientId}/custom_roles/${roleId}.json`;
      axios
        .put(url, dataParams)
        .then((res) => {
          navigator(`/f/clients/${clientId}/settings/roles`);
          toast.success('Role updated successfully!');
        })
        .catch((err) => {
          toast.error(err.response?.data?.errors[0]);
           dispatch(updateRoleStep(stepList.step01));
        }).finally(() => {setLoading(false)});
    }
  };

  return {
    data,
    clientId,
    roleId,
    loading,
    currentStep,
    docxAuth,
    quickActions,
    authDocuments,
    authQuickActions,
    handleSave,
    handleStepChange,
  };
};

export default useRoleHooks;
