import React from 'react';
import isEmpty from 'lodash.isempty';
import UserComment from './UserComment';
import SkeletonLoading from './SkeletonLoading';
import { dayWithDate } from '../../utils';
export default ({ requesting, clientFeed }) => {
  return (
    <>
      {!requesting ? (
        <>
          {isEmpty(clientFeed) ? (
            <>
              <h5>Welcome</h5>
              <p>
                Here you can make announcements, send updates, and make
                conversations with your team mates.
              </p>
            </>
          ) : (
            <ul className='list-unstyled feed-comments'>
              {Object.keys(clientFeed).map((date) => (
                <>
                  <li>
                    <span className={'date'}>{dayWithDate(date)}</span>
                  </li>
                  {clientFeed[date].map((comment) => (
                    <UserComment
                      comment={comment}
                      date={dayWithDate(date, true)}
                    />
                  ))}
                </>
              ))}
            </ul>
          )}
        </>
      ) : (
        <>
          <SkeletonLoading />
          <SkeletonLoading />
        </>
      )}
    </>
  );
};
