import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import { useNavigate, useParams } from 'react-router';
import LeftSidebar from '../../../components/client-settings/LeftSideBar';
import TableComponent from '../../../components/table';
import { stepList, TYPE_TABLE } from '../../../utils';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { deleteRole } from '../../../redux/slices/role-settings/createRole';
import { updateRoleStep } from '../../../redux/slices/role-settings/roleStep';
import debounce from 'lodash.debounce';
import ModelDeleteRole from './ModelDeleteRole';

axios.defaults.xsrfCookieName = 'CSRF-TOKEN';
axios.defaults.xsrfHeaderName = 'X-CSRF-Token';
axios.defaults.withCredentials = true;

const roleColumns = [
  {
    dataField: 'name',
    text: 'Role Name',
    sort: false,
  },
  {
    dataField: 'description',
    text: 'Description',
    sort: false,
  },
  {
    dataField: 'user',
    text: 'User',
    sort: false,
  },
  {
    dataField: 'created_at',
    text: 'Date Created',
    sort: false,
  },
  {
    dataField: 'role_created_by',
    text: 'Created By',
    sort: false,
  },
  {
    dataField: 'last_updated',
    text: 'Last Updated',
    sort: false,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    sort: false,
  },
];

const RoleList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { clientId } = useParams();
  const [roles, setRoles] = useState([]);
  const [roleId, setRoleId] = useState(null);

  const [isShowModel, setIsShowModel] = useState(false);

  const [totalEntries, setTotalEntries] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [searchFlag, setSearchFlag] = useState('');

  const [reload, setReload] = useState(false);
  const [canAccessQuickActions, setCanAccessQuickActions] = useState(false);

  const handleSearch = debounce((term) => {
    setSearchFlag(term);
  }, 500);

  const handleChange = (event) => {
    const term = event.target.value;
    handleSearch(term);
  };

  const handleEdit = (id) => {
    navigate(`/f/clients/${clientId}/settings/roles/edit/${id}`);
  };

  const handleNewRole = () => {
    dispatch(updateRoleStep(stepList.step01));
    dispatch(deleteRole());
    navigate(`/f/clients/${clientId}/settings/roles/new`);
  }

  const handleDeleteRole = useCallback((id) => {
    setRoleId(id);
    setIsShowModel(true);
  }, []);

  useEffect(() => {
    let queryParams = {
      client_id: clientId,
      page: currentPage,
      per_page: sizePerPage,
      query: searchFlag,
    };

    let queryParamsString = new URLSearchParams(queryParams).toString();
    const url = `/api/v1/clients/${clientId}/custom_roles.json?${queryParamsString}`;

    axios.get(url).then((res) => {
      setRoles(res.data?.custom_roles);
      setTotalEntries(res.data.total_entries);
      setCanAccessQuickActions(res.data.can_access_quick_actions);
    });
  }, [currentPage, sizePerPage, searchFlag, clientId, reload]);

  return (
    <Container fluid className='page-content'>
      <h4 className='mb-0 font-size-18 mb-4'>Roles</h4>
      <Row>
        <Col className='w-100'>
          <Row>
            <Col className='col-12 col-md-4 col-lg-3'>
              <LeftSidebar quickActionRole={canAccessQuickActions}/>
            </Col>
            <Col className='col-12 col-md-8 col-lg-9 mt-3 mt-md-0'>
              <div className='w-100 list-position'>
                <div className='w-100 d-flex align-items-center justify-content-end mb-3'>
                  <a href="https://library.bizportservices.com.au/roles-creating-custom-role" target="_blank">
                    <button className={"me-3 btn btn-sm bg-goldenrod"}>
                      How to do?
                    </button>
                  </a>
                  <input
                    className='form-control search-field'
                    type='search'
                    placeholder='Search'
                    onChange={handleChange}
                  />
                  <Button
                    color='primary'
                    className='ml-2'
                    onClick={handleNewRole}
                  >
                    New Role
                  </Button>
                </div>
                <TableComponent
                  columns={roleColumns}
                  data={roles}
                  deleteOption
                  editOption
                  onEdit={(id) => handleEdit(id)}
                  setData={setRoles}
                  pageNum={currentPage}
                  sizePerPage={sizePerPage}
                  totalSize={totalEntries}
                  setPageNum={setCurrentPage}
                  setPerPageSize={setSizePerPage}
                  typeTable={TYPE_TABLE.ROLE_LIST}
                  onDelete={(id) => handleDeleteRole(id)}
                />
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <ModelDeleteRole
        isShowModel={isShowModel}
        setIsShowModel={setIsShowModel}
        roles={roles}
        id={roleId}
        reload={reload}
        setReload={setReload}
      />
    </Container>
  );
};

export default RoleList;
