import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import isEmpty from 'lodash.isempty';
import { Row, Col, Button } from 'reactstrap';
import { FILE_TYPES, TYPE_TABLE } from '../../../utils';
import useRoleHooks from './hook';
import TableComponent from '../../../components/table';
import { BASE_URL } from '../../../../constants';
import axios from 'axios';

const TYPE_FILTER = {
  fileFilter: 0,
  codeFilter: 1,
};

const signedDocumentsColumns = [
  {
    dataField: 'label',
    text: 'Code',
    sort: true,
  },
  {
    dataField: 'name',
    text: 'Name',
    sort: true,
  },
  {
    dataField: 'document_type',
    text: 'Type',
    sort: false,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    sort: false,
  },
  {
    dataField: 'assign',
    text: 'Assign',
    sort: false,
  },
];

const Step03 = () => {
  const { currentStep, handleStepChange, data, clientId } = useRoleHooks();
  const animatedComponents = makeAnimated();
  const [signedDocuments, setSignedDocuments] = useState([]);
  const [documentLabelList, setDocumentLabelList] = useState([]);

  const [filters, setFilters] = useState({
    file_types: [],
    labels: [],
  });
  const [showFilters, setShowFilters] = useState(false);
  const [typeValues, setTypeValues] = useState([]);
  const [labelValues, setLabelValues] = useState([]);

  const searchInputRef = useRef();
  const [search, setSearch] = useState('');
  const [sortOrder, setSortOrder] = useState('asc');
  const [sortField, setSortField] = useState('label');

  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [totalEntries, setTotalEntries] = useState(0)

  const [selectedValues, setSelectedValues] = useState([]);

  const [typingTimeout, setTypingTimeout] = useState(0);
  const [searchFlag, setSearchFlag] = useState('');

  const onChangeFilter = (values, typeFilter) => {
    if (typeFilter === TYPE_FILTER.fileFilter) {
      let types = [];
      values.map((value) => {
        types.push(value.value);
      });
      setFilters({
        ...filters,
        file_types: types,
      });
      setTypeValues(values);
    } else {
      let codes = [];
      values.map((value) => {
        codes.push(value.value);
      });
      setFilters({
        ...filters,
        labels: codes,
      });
      setLabelValues(values);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(() => {
        setSearchFlag(search)
      }, 1000)
    );
  };

  useEffect(() => {
    const fetchDocumentLabelsList = async () => {
      //Waiting for API link
      const url = `${BASE_URL}/api/v1/clients/${clientId}/document_labels.json`;
      const res = await axios.get(url);
      if (res?.data) {
        const labelOptions = res.data.map((code) => {
          return { value: code, label: code };
        });
        setDocumentLabelList(labelOptions);
      }
    };

    fetchDocumentLabelsList();
  }, [clientId]);

  const handleFetchDocsList = () => {
    let queryParamsString = new URLSearchParams({
      status:'completed',
      query: search,
      file_types: filters.file_types,
      codes: filters.labels,
      orderBy: sortField,
      order: sortOrder,
      page: currentPage,
      per_page: sizePerPage,
      client_id: clientId
    }).toString();

    axios.get(`/api/v1/signed_documents.json?${queryParamsString}`).then(res => {
      if(res.data?.documents) {
        setSignedDocuments(res.data?.documents)
        setTotalEntries(res.data?.total_entries)
      }
    })
  }

  useEffect(() => {
    handleFetchDocsList();
  }, [filters, sortField, sortOrder, currentPage, searchFlag, sizePerPage]);

  useEffect(() => {
    if (data.signedDocs && data.signedDocs.length) {
      setSelectedValues(data.signedDocs);
    }
  }, []);

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between w-100 align-items-center mb-3'>
        <Button
          type='button'
          color='primary'
          onClick={() => handleStepChange({ step: currentStep, back: true })}
        >
          Previous
        </Button>
        <Button
          type='button'
          color='primary'
          onClick={() =>
            handleStepChange({ step: currentStep, payload: selectedValues })
          }
        >
          Next
        </Button>
      </div>
      <Row>
        <Col className='col-12'>
          <div className='page-title-box d-flex align-items-center justify-content-between'>
            <h4 className='mb-0 font-size-18'></h4>
            <div className='page-title-right'>
              <div className='d-flex align-items-center'>
                <input
                  ref={searchInputRef}
                  className='form-control search-field'
                  type='search'
                  onChange={handleSearch}
                  placeholder='Search'
                />
                <div
                  className='position-relative'
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}
                >
                  <i className='mdi mdi-24px mdi-filter-variant m-0 text-muted h5' />
                  {(!isEmpty(filters.file_types) ||
                    !isEmpty(filters.labels)) && (
                    <span className='position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-success p-1'>
                      <span className='visually-hidden'></span>
                    </span>
                  )}
                </div>
              </div>
              {showFilters && (
                <div>
                  <div
                    className='overlay'
                    onClick={() => {
                      setShowFilters(!showFilters);
                    }}
                  />
                  <div className='filter-box'>
                    <div className='filter-container'>
                      <h5>Filters</h5>
                      <div className='filter'>
                        <label className='filter-label'>Types</label>
                        <div className='filter-input'>
                          <Select
                            components={animatedComponents}
                            isMulti
                            defaultValue={typeValues}
                            onChange={(values) =>
                              onChangeFilter(values, TYPE_FILTER.fileFilter)
                            }
                            options={FILE_TYPES}
                          />
                        </div>
                      </div>
                      <div className='filter'>
                        <label className='filter-label'>Codes</label>
                        <div className='filter-input'>
                          <Select
                            components={animatedComponents}
                            isMulti
                            defaultValue={labelValues}
                            onChange={(values) =>
                              onChangeFilter(values, TYPE_FILTER.codeFilter)
                            }
                            options={documentLabelList}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col className='col-12'>
          <TableComponent
            columns={signedDocumentsColumns}
            data={signedDocuments}
            pageNum={currentPage}
            sizePerPage={sizePerPage}
            totalSize={totalEntries}
            setPageNum={setCurrentPage}
            setPerPageSize={setSizePerPage}
            selectedValues={selectedValues}
            setSelectedValues={setSelectedValues}
            setSortField={setSortField}
            setSortOrder={setSortOrder}
            openOption
            typeTable={TYPE_TABLE.SIGNED_DOCUMENTS}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Step03;
