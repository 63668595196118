import { Controller } from "stimulus"
import axios from "axios"

export default class extends Controller {
  static targets = []

  connect() {
    if (this.shouldShowModal()) {
      this.autoClickButton()
    }
  }

  shouldShowModal() {
    const policy = document.getElementById('openPolicyModal')?.dataset.status
    const status = document.getElementById('openEventModal')?.dataset.status
    return status === "true" && policy !== "true"
  }

  autoClickButton() {
    const button = document.getElementById('openEventModal')
    if (button) {
      button.click()
    }
  }

  handleUpdateEventPopup() {
    const url = '/api/v1/user/update_event_popup_status'
    const params = { event_popup_shown: 'shown' }
    this.updateStatusEventModal(url, params)
  }

  handleUpdateEventPopupAndOpenTab() {
    const url = '/api/v1/user/update_event_popup_status'
    const params = { event_popup_shown: 'shown' }
    this.updateStatusEventModal(url, params)
    const urlOpenTab = document.getElementById('btnEventImage')?.dataset.url
    if (urlOpenTab) {
      window.open(urlOpenTab, "_blank");
    }
  }

  updateStatusEventModal(url, params) {
    try {
      axios.post(url, params).then((response) => {
        console.log('Popup event confirmation successfully')
      });
    } catch (e) {
      console.log(e);
    }
  }
}
