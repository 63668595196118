import React, { useState, useEffect } from 'react';
import { Row, Col, Button } from 'reactstrap';
import useRoleHooks, { getColumnValues } from "./hook";
import TableComponent from '../../../components/table/index';
import ModelAddQuickAction from '../components/ModelAddQuickAction';
import { useDispatch } from 'react-redux';
import { addQuickActions } from '../../../redux/slices/role-settings/createRole';
import { TYPE_TABLE, filterNodes } from "../../../utils";
import axios from "axios";
import { BASE_URL } from "../../../../constants";
import { updateAuthQuickActions } from "../../../redux/slices/documents/authorizationQuickActions";

const quickActionColumns = [
  {
    dataField: 'name',
    text: 'Name',
    sort: false,
  },
  {
    dataField: 'documents',
    text: 'Documents',
    sort: false,
  },
  {
    dataField: 'actions',
    text: 'Actions',
    sort: false,
  },
];

const getCheckedNodes = (node, checkedValues, checkedNodes) => {
  if (checkedValues.map(item => item.toString()).includes(node.value.toString())) {
    checkedNodes.push(node.name);
  }

  if (node.children) {
    node.children.forEach((childNode) => {
      getCheckedNodes(childNode, checkedValues, checkedNodes);
    });
  }
};

const Step04 = () => {
  const dispatch = useDispatch();
  const { currentStep, handleStepChange, data, clientId, authQuickActions } = useRoleHooks();
  const [quickActions, setQuickActions] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const [isShowModel, setIsShowModel] = useState(false);

  const [idAction, setIdAction] = useState(null);

  useEffect(() => {
    const fetchDocumentsList = () => {
      axios
        .get(
          `${BASE_URL}/api/v1/clients/${clientId}/quick_actions/get_folder_data.json`
        )
        .then((res) => {
          const data = res.data.folders.map(filterNodes).filter(Boolean);
          const expandedData = getColumnValues(data, "value", []);
          dispatch(
            updateAuthQuickActions({
              expandedData: expandedData.flat(),
              data: data,
            })
          );
        });
    };

    fetchDocumentsList();
  }, []);

  useEffect(() => {
    const newData = data.quickActions.slice(
      (currentPage - 1) * sizePerPage,
      (currentPage - 1) * sizePerPage + sizePerPage
    );

    const convertedData = newData.map((item) => {
      let labels = [];
      authQuickActions.data.forEach((node) => {
        console.log("xxx", node);
        getCheckedNodes(node, item.company_document_ids, labels);
      });
      return {
        ...item,
        documents: labels.join('\n'),
      };
    });
    setQuickActions([...convertedData]);
  }, [currentPage, sizePerPage, data.quickActions]);

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between w-100 align-items-center mb-3">
        <Button
          type="button"
          color="primary"
          onClick={() => handleStepChange({ step: currentStep, back: true })}
        >
          Previous
        </Button>
        <Button
          type="button"
          color="primary"
          onClick={() => handleStepChange({ step: currentStep })}
        >
          Next
        </Button>
      </div>
      <Row>
        <Col className="col-12">
          <div className="d-flex justify-content-end w-100">
            <Button
              className="btn btn-primary mb-4 mt-4"
              color="primary"
              type="button"
              onClick={() => setIsShowModel(true)}
            >
              New Quick Action
            </Button>
          </div>
          <TableComponent
            columns={quickActionColumns}
            data={quickActions}
            setData={(array) => dispatch(addQuickActions(array))}
            pageNum={currentPage}
            sizePerPage={sizePerPage}
            totalSize={data.quickActions.length}
            setPageNum={setCurrentPage}
            setPerPageSize={setSizePerPage}
            deleteOption
            editOption
            onEdit={(id) => {
              setIdAction(id);
              setIsShowModel(true);
            }}
            typeTable={TYPE_TABLE.QUICK_ACTION}
          />
        </Col>
      </Row>
      <ModelAddQuickAction
        isShowModel={isShowModel}
        setIsShowModel={setIsShowModel}
        idAction={idAction}
        setIdAction={setIdAction}
        clientId={clientId}
      />
    </React.Fragment>
  );
};

export default Step04;
