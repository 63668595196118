import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [];

  connect() {}

  toggleForm() {
    var x = document.getElementById('step-1');
    var y = document.getElementById('step-2');
    var z = document.getElementById('circle-1');
    var a = document.getElementById('circle-2');

    if (x.style.display === 'none') {
      x.style.display = 'block';
      y.style.display = 'none';
      a.style.backgroundColor = '#A4A7B1';
      a.style.borderColor = '#A4A7B1';
      z.style.backgroundColor = '#CA2318';
    } else {
      x.style.display = 'none';
      y.style.display = 'block';
      a.style.borderColor = '#CA2318';
      a.style.backgroundColor = '#CA2318';
    }
  }

  toggleFormNext() {
    var step1 = document.getElementById('step-1');
    var step2 = document.getElementById('step-2');
    var step3 = document.getElementById('step-3');

    var circle1 = document.getElementById('circle-1');
    var circle2 = document.getElementById('circle-2');
    var circle3 = document.getElementById('circle-3');

    if (step2.style.display === 'block') {
      step2.style.display = 'none';
      step3.style.display = 'block';
      circle3.style.borderColor = '#CA2318';
      circle3.style.backgroundColor = '#CA2318';
    } else if (step3.style.display === 'block') {
      step3.style.display = 'none';
    }  else {
      step1.style.display = 'none';
      step2.style.display = 'block';
      circle2.style.borderColor = '#CA2318';
      circle2.style.backgroundColor = '#CA2318';
    }
  }

  toggleFormPrevious() {
    var step1 = document.getElementById('step-1');
    var step2 = document.getElementById('step-2');
    var step3 = document.getElementById('step-3');

    var circle1 = document.getElementById('circle-1');
    var circle2 = document.getElementById('circle-2');
    var circle3 = document.getElementById('circle-3');

    if (step2.style.display === 'block') {
      step2.style.display = 'none';
      step1.style.display = 'block';
      circle2.style.borderColor = '#A4A7B1';
      circle2.style.backgroundColor = '#A4A7B1';
    } else if (step3.style.display === 'block') {
      step3.style.display = 'none';
      step2.style.display = 'block';
      circle3.style.borderColor = '#A4A7B1';
      circle3.style.backgroundColor = '#A4A7B1';
    }
  }
}
