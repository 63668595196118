import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./App/redux/store";
import { Provider } from 'react-redux';
$(document).on("turbolinks:load", function () {
  const rootElement = document.getElementById("root");
  if (rootElement) {
    ReactDOM.render(
      <Provider store={store}>
        <App />
      </Provider>,
      rootElement
    );
  }
});
