import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['documentUrl', 'documentName'];

  connect() {}

  downloadPdf(e) {
    e.preventDefault();
    const element = e.target;
    const documentUrl = element.dataset.documenturl;
    const documentName = element.dataset.documentname;
    var link = document.createElement('a');
    link.download = documentName;
    link.target = '_blank';
    link.href = documentUrl;
    document.body.appendChild(link);
    link.click();
    window.URL.revokeObjectURL(documentUrl);
    link.remove();
  }
}
