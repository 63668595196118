import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Table, FormGroup, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { updatePermissions, addBranches } from '../../../redux/slices/role-settings/createRole';
import axios from 'axios'
import './style.scss';

const radioValues = [
  {
    id: 1,
    value: true,
    text: 'Yes',
  },
  {
    id: 2,
    value: false,
    text: 'No',
  },
];

const documentValues = [
  {
    id: 'authorized',
    value: 'authorized',
    text: 'Authorized Documents',
  },
  {
    id: 'authorized',
    value: 'all',
    text: 'All Documents',
  },
];

const userDocumentValues = [
  {
    id: 'authorized',
    value: 'authorized',
    text: 'Assigned Documents',
  },
  {
    id: 'authorized',
    value: 'all',
    text: 'All Documents',
  },
];

const taskValues = [
  {
    id: 'authorized',
    value: 'authorized',
    text: 'Assigned',
  },
  {
    id: 'authorized',
    value: 'all',
    text: 'All Tasks',
  },
];

const editHRDetailsValues = [
  {
    id: 'all',
    value: 'all',
    text: 'All User Details',
  },
  {
    id: 'personal',
    value: 'personal',
    text: 'Personal Details',
  },
  {
    id: 'not_allowed',
    value: 'not_allowed',
    text: 'Not Allowed',
  },
]


const handleDefaultValue = (type, fieldName) => {
  if(fieldName === 'edit_hr_details') return 'not_allowed';
  if (type === 'radio' && fieldName === 'only_view_docs' ) return false;
  if(type === 'radio') return false;
  if(type === 'select') return 'authorized';
}

const PermissionComponent = ({businessIsFranchise}) => {
  const permissionTemplate = [
    {
      id: 1,
      name: 'Can see ' + (businessIsFranchise ? 'Franchises' : 'Branches'),
      field: 'see_branches',
      desc: '',
      action: {
        type: 'radio',
        value: [...radioValues],
      },
    },
    {
      id: 2,
      name: 'View only Access',
      field: 'only_view_docs',
      desc: '',
      action: {
        type: 'radio',
        value: [...radioValues],
      },
    },
    {
      id: 3,
      name: 'Create, Edit and Assign Documents/Forms',
      field: 'manage_docs',
      desc: '',
      action: {
        type: 'radio',
        value: [...radioValues],
      },
    },
    {
      id: 4,
      name: 'User Documents',
      field: 'submissions',
      desc: '',
      action: {
        type: 'select',
        value: [...userDocumentValues],
      },
    },
    {
      id: 5,
      name: 'Authority Documents',
      field: 'signed_documents',
      desc: '',
      action: {
        type: 'select',
        value: [...documentValues],
      },
    },
    {
      id: 6,
      name: 'Activities',
      field: 'activities',
      desc: '',
      action: {
        type: 'select',
        value: [...documentValues],
      },
    },
    {
      id: 7,
      name: 'Tasks',
      field: 'tasks',
      desc: '',
      action: {
        type: 'select',
        value: [...taskValues],
      },
    },
    {
      id: 8,
      name: 'Access Registers ',
      field: 'access_registers',
      desc: '',
      action: {
        type: 'radio',
        value: [...radioValues],
      },
    },
    {
      id: 9,
      name: 'Dashboard Template Status',
      field: 'dashboard_template_status',
      desc: '',
      action: {
        type: 'radio',
        value: [...radioValues],
      },
    },
    {
      id: 10,
      name: 'Access Settings Tab',
      field: 'access_settings',
      desc: '',
      action: {
        type: 'radio',
        value: [...radioValues],
      },
    },
    {
      id: 11,
      name: 'Add, Edit and View User',
      field: 'manage_users',
      desc: '',
      action: {
        type: 'radio',
        value: [...radioValues],
      },
    },
    {
      id: 12,
      name: 'Can Update Authority Documents tab Status',
      field: 'can_update_signed_documents_tab_status',
      desc: '',
      action: {
        type: 'radio',
        value: [...radioValues],
      },
    },
    {
      id: 13,
      name: 'Edit HR Details',
      field: 'edit_hr_details',
      desc: '',
      action: {
        type: 'select',
        value: [...editHRDetailsValues],
      },
    },
  ]

  const { clientId } = useParams();
  const [availableBranches, setAvailableBranches] = useState([])
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState('')

  const dispatch = useDispatch();
  const data = useSelector((state) => state.createRole);
  const { permission, branches } = data;

  const handleChangeSelect = (e) => {
    const value = e.target.value;
    const idSelect = e.target.id;
    const values = permission.map((p) => {
      if (p.name == idSelect) {
        return {
          name: p.name,
          value: value,
          description: p.description,
        };
      }
      return p;
    });
    dispatch(updatePermissions(values));
  };

  const handleChangeDesc = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    const values = permission.map((p) => {
      if (p.name == id) {
        return {
          ...p,
          description: value,
        };
      }
      return p;
    });
    dispatch(updatePermissions(values));
  };

  const handleChangeRadio = (e) => {
    const value = e.target.value;
    const id = e.target.id;
    const values = permission.map((p) => {
      if (p.name == id) {
        return {
          name: p.name,
          value: value == 'true' ? true : false,
          description: p.description,
        };
      }
      return p;
    });
    dispatch(updatePermissions(values));
  };

  useEffect(() => {
    if (permission && permission?.length === 0) {
      const values = permissionTemplate.map((p) => {
        return {
          name: p.field,
          description: p.desc,
          value: handleDefaultValue(p.action.type, p.field)
        };
      });
      dispatch(updatePermissions(values));
    }

    if (!availableBranches.length) {
      axios.get(`/api/v1/clients/${clientId}/branches.json`).then(res => {
        setAvailableBranches(res.data.all_branches)
      })
    }
  }, []);

  const handleAddToBranches = () => {
    const branch = availableBranches.find(b => b.id === parseInt(selectedBranch))
    if(branch) {
      dispatch(addBranches([...branches, branch]))
      setModalOpen(false)
      setSelectedBranch('')
    } else {
      setModalOpen(false)
      setSelectedBranch('')
    }
  }

  return (
    <React.Fragment>
    <div className='d-flex'>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-unlock" viewBox="0 0 16 16">
        <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2zM3 8a1 1 0 0 0-1 1v5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H3z"/>
      </svg>
      <h4 className='mx-1 mb-0 font-size-18 mb-4'>Permissions</h4>
    </div>
      <Table borderless className='mb-4'>
        <thead>
          <tr style={{ background: 'transparent' }}>
            <th style={{ paddingLeft: 0 }}>Permission Name</th>
            <th>Description</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {permissionTemplate.map((p, index) => {
            const result = permission?.find((v) => v.name === p.field);

            return (
            <>
              <tr style={{ background: 'transparent' }} key={p.id}>
                <td style={{ paddingLeft: 0 }}>{p.name}</td>
                <td>
                  <Input
                    type='text'
                    name={`desc-${p.id}`}
                    className='custom-input'
                    id={p.field}
                    onChange={handleChangeDesc}
                    value={
                      permission.length ? permission[index].description : ''
                    }
                  />
                </td>
                <td>
                  {p.action.type === 'radio' ? (
                    <FormGroup
                      className='d-flex'
                      style={{ marginBottom: '0px' }}
                    >
                      {p.action.value.map((v, index) => {
                        return (
                          <FormGroup
                            check
                            style={index == 0 ? { marginRight: '12px' } : {}}
                            key={`radio-${index}`}
                          >
                            <Label check>
                              <Input
                                type='radio'
                                name={`radio-${p.id}`}
                                value={v.value}
                                id={p.field}
                                onChange={handleChangeRadio}
                                checked={result && result?.value === v.value}
                              />
                              {v.text}
                            </Label>
                          </FormGroup>
                        );
                      })}
                    </FormGroup>
                  ) : (
                    <FormGroup style={{ marginBottom: '0px' }}>
                      <Input
                        type='select'
                        name={p.field}
                        id={p.field}
                        onChange={handleChangeSelect}
                      >
                        {p.action.value.map((v) => {
                          return (
                            <option
                              key={p.field + '-' + v.value}
                              value={v.value}
                              selected={v.value === ((result && result.value) || 'authorized')}
                            >
                              {v.text}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  )}
                </td>
              </tr>
              {p.field === 'see_branches' && result?.value &&
                <tr>
                  <td colSpan={3}>
                    <div className="info-card">
                      <div className="header px-3">
                        <div className="icon-text">
                          <div className="heading">{businessIsFranchise ? 'Franchises' : 'Branches'}</div>
                        </div>
                        <div className="btn btn-secondary btn-sm" onClick={() => { setModalOpen(true) }}>+ Add</div>
                      </div>
                      {branches.map(branch => {
                        return (
                          <div className="d-flex justify-content-between align-items-center px-3 pb-2">
                            <a href={`/clients/${branch.id}`}>{branch.name}</a>
                          </div>
                        )
                      })
                      }
                    </div>
                  </td>
                </tr>
              }
            </>
            );
          })}
        </tbody>
      </Table>
      <Modal
        isOpen={modalOpen}
        toggle={() => {
          setModalOpen(!modalOpen);
        }}
        centered={true}
      >
        <ModalHeader>Add Employee to {businessIsFranchise ? 'Franchise' : 'Branch'}</ModalHeader>
        <ModalBody>
          <Input
            type='select'
            name='select-branch'
            id={'select-branch'}
            onChange={(e) => { setSelectedBranch(e.target.value) }}
          >
            <option
              key={'empty'}
              value={''}
            >
              Select {businessIsFranchise ? 'franchise' : 'branch'}
            </option>
            {availableBranches.filter(b => parseInt(b.id) !== parseInt(clientId) && !branches.map(b=> b.id).includes(b.id)).map((branch) => {
              return (
                <option
                  key={branch.id + '-' + branch.name}
                  value={branch.id}
                  selected={selectedBranch === branch.id}
                >
                  {branch.name}
                </option>
              );
            })}
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddToBranches}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default PermissionComponent;
