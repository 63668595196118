import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['documentList'];

  connect() {
    document.addEventListener('change', this.checkboxChange.bind(this));
  }

  checkboxChange(e) {
    const allThings = this.nodeArray('input');
    let check = e.target;

    //  exit if change event did not come from
    //  our list of allThings
    if (allThings.indexOf(check) === -1) return;

    //  check/un-check children (includes check itself)
    const children = this.nodeArray('input', check.parentNode.parentNode.parentNode);
    children.forEach((child) => (child.checked = check.checked));

    //  traverse up from target check
    while (check) {
      //  find parent and sibling checkboxes
      const parent = check.parentNode.closest(['ul']).parentNode.querySelector('input');
      const siblings = this.nodeArray(
        'input',
        parent.closest('li').querySelector(['ul'])
      );

      //  get checked state of siblings
      //  are every or some siblings checked (using Boolean as test f unction)
      const checkStatus = siblings.map((check) => check.checked);
      const every = checkStatus.every(Boolean);
      const some = checkStatus.some(Boolean);

      //  prepare for nex loop
      check = check != parent ? parent : 0;
    }
  }

  nodeArray(selector, parent = this.documentListTarget) {
    return [].slice.call(parent.querySelectorAll(selector));
  }
}
