import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['isHeadOffice', 'chooseHeadOffice', 'documentList'];

  connect() {
    this.chooseHeadOfficeTarget.style.display = 'none';
    $('.select-document').on('change', this.checkboxChange.bind(this));
  }

  checkboxChange(e) {
    let check = e.target;
    const allThings = this.getAll('input', check);
    //  exit if change event did not come from
    //  our list of allThings
    if (allThings.indexOf(check) === -1) return;

    //  check/un-check children (includes check itself)
    const children = this.nodeArray('input', check.parentNode);
    children.forEach((child) => (child.checked = check.checked));
    //  traverse up from target check
    while (check) {
      //  find parent and sibling checkboxes
      const parent = check.closest(['ul']).parentNode.querySelector('input');
      const siblings = this.nodeArray(
        'input',
        parent.closest('li').querySelector(['ul'])
      );

      //  get checked state of siblings
      //  are every or some siblings checked (using Boolean as test f unction)
      const checkStatus = siblings.map((check) => check.checked);
      const every = checkStatus.every(Boolean);
      const some = checkStatus.some(Boolean);

      //  check parent if all siblings are checked
      //  set indeterminate if not all and not none are checked
      parent.checked = every;
      parent.indeterminate = !every && every !== some;

      //  prepare for nex loop
      check = check != parent ? parent : 0;
    }
  }
  nodeArray(selector, parent) {
    return [].slice.call(parent.querySelectorAll(selector));
  }

  getAll(selector, check) {
    parent = check.closest('.select-document');
    return [].slice.call(parent.querySelectorAll(selector));
  }

  toggleHeadOffice() {
    if (this.isHeadOfficeTarget.checked) {
      this.chooseHeadOfficeTarget.style.display = 'none';
    } else {
      this.chooseHeadOfficeTarget.style.display = 'block';
    }
  }
  toggleFormNext() {
    var step1 = document.getElementById('step-1');
    var step2 = document.getElementById('step-2');
    var step3 = document.getElementById('step-3');
    var step4 = document.getElementById('step-4');
    var step5 = document.getElementById('step-5');

    var circle1 = document.getElementById('circle-1');
    var circle2 = document.getElementById('circle-2');
    var circle3 = document.getElementById('circle-3');
    var circle4 = document.getElementById('circle-4');
    var circle5 = document.getElementById('circle-5');

    if (step2.style.display === 'block') {
      step2.style.display = 'none';
      step3.style.display = 'block';
      circle3.style.borderColor = '#CA2318';
      circle3.style.backgroundColor = '#CA2318';
    } else if (step3.style.display === 'block') {
      step3.style.display = 'none';
      step4.style.display = 'block';
      circle4.style.borderColor = '#CA2318';
      circle4.style.backgroundColor = '#CA2318';
    } else if (step4.style.display === 'block') {
      step4.style.display = 'none';
      step5.style.display = 'block';
      circle5.style.borderColor = '#CA2318';
      circle5.style.backgroundColor = '#CA2318';
    } else {
      step1.style.display = 'none';
      step2.style.display = 'block';
      circle2.style.borderColor = '#CA2318';
      circle2.style.backgroundColor = '#CA2318';
    }
  }

  toggleFormPrevious() {
    var step1 = document.getElementById('step-1');
    var step2 = document.getElementById('step-2');
    var step3 = document.getElementById('step-3');
    var step4 = document.getElementById('step-4');
    var step5 = document.getElementById('step-5');

    var circle1 = document.getElementById('circle-1');
    var circle2 = document.getElementById('circle-2');
    var circle3 = document.getElementById('circle-3');
    var circle4 = document.getElementById('circle-4');
    var circle5 = document.getElementById('circle-5');

    if (step2.style.display === 'block') {
      step2.style.display = 'none';
      step1.style.display = 'block';
      circle2.style.borderColor = '#A4A7B1';
      circle2.style.backgroundColor = '#A4A7B1';
    } else if (step3.style.display === 'block') {
      step3.style.display = 'none';
      step2.style.display = 'block';
      circle3.style.borderColor = '#A4A7B1';
      circle3.style.backgroundColor = '#A4A7B1';
    } else if (step4.style.display === 'block') {
      step4.style.display = 'none';
      step3.style.display = 'block';
      circle4.style.borderColor = '#A4A7B1';
      circle4.style.backgroundColor = '#A4A7B1';
    } else if (step5.style.display === 'block') {
      step5.style.display = 'none';
      step4.style.display = 'block';
      circle5.style.borderColor = '#A4A7B1';
      circle5.style.backgroundColor = '#A4A7B1';
    }
  }
}
