import { createSlice } from "@reduxjs/toolkit";

export const authQuickActionsSlice = createSlice({
  name: "authorizationQuickActions",
  initialState: {
    expandedList: [],
    data: [],
  },
  reducers: {
    updateAuthQuickActions: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateAuthQuickActions } = authQuickActionsSlice.actions;

export default authQuickActionsSlice.reducer;
