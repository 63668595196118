import { createSlice } from '@reduxjs/toolkit';

export const authDocumentsSlice = createSlice({
  name: 'authorizationDocuments',
  initialState: {
    expandedList: [],
    data: [],
    originValues: []
  },
  reducers: {
    updateAuthDocuments: (state, action) => {
      return action.payload;
    },
  },
});

export const { updateAuthDocuments } = authDocumentsSlice.actions;

export default authDocumentsSlice.reducer;
