import React, { lazy, Suspense } from "react";
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route} from "react-router-dom";
import './App/assets/css/icons.css';
import ClientRoleSettings from './App/routes/client-roles';
import ClientRoleNew from './App/routes/client-roles/steps';

const ClientDocuments = lazy(() => import('./App/routes/client-documents'));
const ClientDocumentsHistory = lazy(() =>
  import('./App/routes/client-documents-history')
);
const ClientFeed = lazy(() => import('./App/routes/client-feed'));
const ClientForms = lazy(() => import('./App/routes/client-forms'));
const ClientFormDetail = lazy(() =>
  import('./App/routes/client-forms/details')
);
const ClientFormSubmissions = lazy(() =>
  import('./App/routes/client-forms/submissions')
);
const ClientTasks = lazy(() => import('./App/routes/client-tasks'));
const ClientTasksNew = lazy(() => import('./App/routes/client-tasks/new'));
const ClientTasksEdit = lazy(() => import('./App/routes/client-tasks/edit'));
const ClientTrash = lazy(() => import('./App/routes/client-trash'));
const ClientSettings = lazy(() => import('./App/routes/client-settings'));
const ClientTrashDocuments = lazy(() =>
  import('./App/routes/client-trash/documents')
);
const ClientTrashUsers = lazy(() => import('./App/routes/client-trash/users'));
const Documents = lazy(() => import('./App/routes/documents'));
const EditClientForm = lazy(() => import('./App/routes/client-forms/edit'));
const EditForm = lazy(() => import('./App/routes/forms/edit'));
const Forms = lazy(() => import('./App/routes/forms'));
const FormPreview = lazy(() => import('./App/routes/forms/preview'));
const FormDetail = lazy(() => import('./App/routes/forms/details'));
const NewForm = lazy(() => import('./App/components/html-form-viewer/new'));
const FormSubmissionView = lazy(() =>
  import('./App/routes/client-forms/submission')
);
const EditSubmission = lazy(() =>
  import('./App/components/html-form-viewer/edit')
);
const ClientNotificationSettings = lazy(() =>
  import('./App/components/client-settings')
);

const TemplateDocumentView = lazy(() =>
  import('./App/routes/template-document-view')
);
const DocSubmissionView = lazy(() =>
  import('./App/routes/doc-submission-view')
);
const ClientDocumentView = lazy(() =>
  import('./App/routes/client-document-view')
);
const DocumentAssignmentView = lazy(() =>
  import('./App/routes/document-assignment-view')
);

const EditClientDocuments = lazy(() =>
  import('./App/routes/client-document-edit')
);

const QuickActions = lazy(() =>
  import('./App/components/client-settings/quick-actions')
);

const AddActionComponent = lazy(() =>
  import('./App/components/client-settings/quick-actions/add-action')
);

const SignDocuments = lazy(() => import('./App/routes/signed-documents'));

const PermissionSettings = lazy(() =>
  import('./App/routes/client-permission-settings')
);

const DocumentAuthorizations = lazy(() =>
  import("./App/routes/document-authorizations")
);

const Submissions = lazy(() => import("./App/routes/submissions"));

const EmployeePage = lazy(() =>
  import('./App/routes/employee')
);

const AssignDocumentsSettings = lazy(() =>
  import("./App/routes/user-assign-documents-settings")
);

const QuickActionsSettings = lazy(() =>
  import("./App/routes/user-quick-actions-settings")
);

const AssignTasksSettings = lazy(() =>
  import("./App/routes/user-assign-tasks-settings")
);

const Spinner = () => (
  <div className='loading-container'>
    <span className='spinner-border' role='status' aria-hidden='true'></span>
  </div>
);

const App = () => {
  return (
    <React.Fragment>
      <ToastContainer position="bottom-right" />
      <BrowserRouter>
        <Suspense fallback={<Spinner />}>
          <Routes>
            <Route path="/f/sign-documents" element={<SignDocuments />} />
            <Route
              path="/f/clients/:clientId/sign-documents"
              element={<SignDocuments />}
            />
            <Route path="/f/documents" element={<Documents />} />
            <Route
              path="/f/documents/:documentId"
              element={<TemplateDocumentView />}
            />
            <Route
              path="/f/doc_submissions/:docSubmissionId"
              element={<DocSubmissionView />}
            />
            <Route
              path="/f/clients/:clientId/documents"
              element={<ClientDocuments />}
            />
            <Route
              path="/f/clients/:clientId/documents/:documentId"
              element={<ClientDocumentView />}
            />
            <Route
              path="/f/document_assignments/:assignmentId"
              element={<DocumentAssignmentView />}
            />
            <Route
              path="/f/clients/:clientId/documents/edit"
              element={<EditClientDocuments />}
            />
            <Route
              path="/f/clients/:clientId/documents/:id/history"
              element={<ClientDocumentsHistory />}
            />
            <Route path="/f/templates" element={<Forms />} />
            <Route path="/f/templates/:id" element={<FormPreview />} />
            <Route path="/f/templates/:id/details" element={<FormDetail />} />
            <Route path="/f/templates/:id/edit" element={<EditForm />} />
            <Route
              path="/f/clients/:clientId/templates"
              element={<ClientForms />}
            />
            <Route
              path="/f/clients/:clientId/templates/:id/details"
              element={<ClientFormDetail />}
            />
            <Route
              path="/f/clients/:clientId/templates/:templateId/submissions"
              element={<ClientFormSubmissions />}
            />
            <Route
              path="/f/clients/:clientId/templates/:id/edit"
              element={<EditClientForm />}
            />
            {/* <Route path='/f/templates/editor' element={<FormEditor />} /> */}
            <Route
              path="/f/company_documents/:company_document_id/submissions/new"
              element={<NewForm />}
            />
            <Route
              path="/f/company_documents/:company_document_id/submissions/:submission_id/edit"
              element={<EditSubmission />}
            />
            <Route
              path="/f/company_documents/:company_document_id/submissions/:submission_id/show"
              element={<FormSubmissionView />}
            />
            <Route
              path="/f/clients/:clientId/tasks"
              element={<ClientTasks />}
            />
            <Route
              path="/f/clients/:clientId/tasks/new"
              element={<ClientTasksNew />}
            />
            <Route
              path="/f/clients/:clientId/tasks/:taskId/edit"
              element={<ClientTasksEdit />}
            />
            <Route path="/f/clients/:clientId/feed" element={<ClientFeed />} />
            <Route
              path="/f/clients/:clientId/settings"
              element={<ClientSettings />}
            >
              <Route
                path="/f/clients/:clientId/settings/notifications"
                element={<ClientNotificationSettings />}
              />
            </Route>
            <Route
              path="/f/clients/:clientId/settings/roles"
              element={<ClientRoleSettings />}
            />
            <Route
              path="/f/clients/:clientId/settings/roles/new"
              element={<ClientRoleNew />}
            />
            <Route
              path="/f/clients/:clientId/settings/roles/edit/:roleId"
              element={<ClientRoleNew editMode />}
            />
            <Route
              path="/f/clients/:clientId/settings/quick-actions"
              element={<QuickActions />}
            />
            <Route
              path="/f/clients/:clientId/settings/quick-actions/new"
              element={<AddActionComponent />}
            />
            <Route
              path="/f/clients/:clientId/settings/quick-actions/edit/:quickActionId"
              element={<AddActionComponent />}
            />
            <Route
              path="/f/clients/:clientId/profile/:currentUserId/quick-actions/new"
              element={<AddActionComponent privateType />}
            />
            <Route
              path="/f/clients/:clientId/profile/:currentUserId/quick-actions/edit/:quickActionId"
              element={<AddActionComponent />}
            />
            <Route
              path="/f/clients/:clientId/users/:userId/quick-actions/new"
              element={<AddActionComponent privateType />}
            />
            <Route
              path="/f/clients/:clientId/users/:userId/quick-actions/edit/:quickActionId"
              element={<AddActionComponent />}
            />
            <Route
              path="/f/clients/:clientId/settings/roles"
              element={<ClientRoleSettings />}
            />
            <Route
              path="/f/clients/:clientId/settings/roles/new"
              element={<ClientRoleNew />}
            />
            <Route
              path="/f/clients/:clientId/settings/roles/edit/:roleId"
              element={<ClientRoleNew editMode />}
            />
            <Route path="/f/clients/:clientId/trash" element={<ClientTrash />}>
              <Route
                path="/f/clients/:clientId/trash/documents"
                element={<ClientTrashDocuments />}
              />
              <Route
                path="/f/clients/:clientId/trash/users"
                element={<ClientTrashUsers />}
              />
            </Route>
            <Route
              path="/f/clients/:clientId/submissions"
              element={<Submissions />}
            />
            <Route
              path="/f/clients/:clientId/users/new"
              element={<EmployeePage />}
            />
            <Route
              path="/f/clients/:clientId/users/:userId/edit"
              element={<EmployeePage editMode />}
            />
            <Route
              path="/f/users/:userId/permissions"
              element={<PermissionSettings />}
            />
            <Route
              path="/f/users/:userId/doc_authorizations"
              element={<DocumentAuthorizations />}
            />
            <Route
              path="/f/clients/:clientId/users/:userId/assign-documents"
              element={<AssignDocumentsSettings />}
            />
            <Route
              path="/f/clients/:clientId/users/:userId/quick-actions"
              element={<QuickActionsSettings />}
            />
            <Route
              path="/f/clients/:clientId/users/:userId/assign-tasks"
              element={<AssignTasksSettings />}
            />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
