import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import useRoleHooks from './hook';
import PermissionComponent from '../components/Permission';
import { LoadingButton } from '../../../components/buttons';
import { useParams } from 'react-router';
import axios from 'axios'

const Step06 = () => {
  const { clientId } = useParams();
  const [company, setCompany] = useState({});
  const { currentStep, roleId, loading, handleStepChange, handleSave } = useRoleHooks();

  useEffect(() => {
    const url = `/api/v1/companies/${clientId}.json?`;

    axios.get(url).then((res) => {
      setCompany(res.data);
    });
  }, []);

  return (
    <React.Fragment>
      <div className='d-flex justify-content-between w-100 align-items-center mb-3'>
        <Button
          type='button'
          color='primary'
          onClick={() => handleStepChange({ step: currentStep, back: true })}
        >
          Previous
        </Button>
        <LoadingButton
          content={roleId ? 'Update Role' : 'Create Role'}
          loading={loading}
          onClick={handleSave}
        />
      </div>
      <PermissionComponent businessIsFranchise={company.business_is_franchise} />
    </React.Fragment>
  );
};

export default Step06;
