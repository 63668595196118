import React, { memo, useMemo, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import LoadingSpinner from '../../../components/FormList/LoadingSpinner';
import { deleteRole, updateRole } from '../../../redux/slices/role-settings/createRole';
import Step01 from './Step01';
import Step02 from './Step02';
import Step03 from './Step03';
import Step04 from './Step04';
import Step05 from './Step05';
import Step06 from './Step06';
import { stepList } from '../../../utils/index';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { updateRoleStep } from '../../../redux/slices/role-settings/roleStep';

export const stepsData = [
  {
    id: 1,
    title: 'Role Details',
  },
  {
    id: 2,
    title: 'Authorization',
  },
  {
    id: 3,
    title: 'Getting Signature',
  },
  {
    id: 4,
    title: 'Quick Actions',
  },
  {
    id: 5,
    title: 'Tasks',
  },
  {
    id: 6,
    title: 'Permissions',
  },
];

const StepList = memo(({ currentStep }) => {
  return (
    <div className='stepwizard'>
      <div className='stepwizard-row setup-panel tepwizard-row-custom'>
        {stepsData.map((step) => {
          return (
            <div
              className='stepwizard-step col-xs-3 me-5 d-flex justify-content-center align-items-center flex-column'
              key={step.id}
            >
              <button
                className={`btn btn-circle text-white ${
                  currentStep >= step.id ? 'btn-success' : 'btn-default2'
                }`}
                style={{ cursor: 'default' }}
              >
                {step.id}
              </button>
              <p>
                <small>{step.title}</small>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
});

const ClientRoleNew = ({ editMode }) => {
  const dispatch = useDispatch();
  const { clientId, roleId } = useParams();
  const currentStep = useSelector((state) => state.roleStep);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (editMode) {
      setLoading(true);
      dispatch(updateRoleStep(stepList.step01));
      dispatch(deleteRole());

      const url = `/api/v1/clients/${clientId}/custom_roles/${roleId}.json?`;
      axios.get(url).then((res) => {
        const {
          name,
          description,
          quick_actions,
          tasks,
          signed_document_ids,
          permissions,
          authorized_document_ids,
          branches
        } = res.data.custom_role;

        const convertedData = {
          details: {
            name,
            desc: description
          },
          docxAuth: [...authorized_document_ids],
          signedDocs: [...signed_document_ids],
          quickActions: [...quick_actions],
          tasks: [...tasks],
          permission: [...permissions],
          branches: [...branches],
        }

        dispatch(updateRole(convertedData));
      }).finally(() => {
        setLoading(false);
      })
    }
  }, []);

  const renderSteps = useMemo(() => {
    switch (currentStep) {
      case 1:
        return <Step01 />;
      case 2:
        return <Step02 />;
      case 3:
        return <Step03 />;
      case 4:
        return <Step04 />;
      case 5:
        return <Step05 />;
      case 6:
        return <Step06 />;
      default:
        return <Step01 />;
    }
  }, [currentStep]);

  return (
    <Container className='pt-4 pb-4'>
      <Row>
        <Col className='col-12'>
          <h3>{editMode ? 'Update Role' : 'New Role'}</h3>
          <StepList currentStep={currentStep} />
        </Col>
      </Row>
      {loading && editMode ? <LoadingSpinner /> : renderSteps}
    </Container>
  );
};

export default ClientRoleNew;
