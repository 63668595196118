import React from 'react';
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap';

const Select = ({
  label,
  errorMessage,
  options,
  inputId,
  name,
  handleChange,
  isRequired,
  touched,
  value,
  handleBlue,
  hideTitle,
  editMode
}) => {
  const invalid =
    touched && errorMessage && errorMessage.length > 0 ? true : false;
  const valid = editMode && value;
  return (
    <FormGroup>
      <Label for={inputId}>
        {label}
        {isRequired && <span className="text-danger">*</span>}
      </Label>
      <Input
        valid={valid}
        id={inputId}
        name={name}
        type="select"
        invalid={invalid}
        onChange={handleChange}
        onBlur={handleBlue}
        value={value || ""}
      >
        {!hideTitle && <option value="">Select an option</option>}
        {options &&
          options.map((item) => {
            return (
              <option value={item.id} key={item.id} className="text-capitalize">
                {item.label}
              </option>
            );
          })}
      </Input>
      {touched && errorMessage && <FormFeedback>{errorMessage}</FormFeedback>}
    </FormGroup>
  );
};

export default Select;
