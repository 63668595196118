import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const LoadingSpinner = () => {
  return (
    <>
      <Container>
        <Row>
          <Col>
            <div className='loading-container'>
              <div className='spinner-border text-primary m-1' role='status'>
                <span className='sr-only'>Loading...</span>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default LoadingSpinner;
