import { createSlice } from '@reduxjs/toolkit';

export const createUser = createSlice({
  name: 'createUser',
  initialState: {
    details: null,
    docxAuth: [],
    signedDocs: [],
    quickActions: [],
    tasks: [],
    permissions: [],
    branches: [],
    fetchedStatus: false,
    accessHrDocuments: false
  },
  reducers: {
    addPersonalDetails: (state, action) => {
      state.details = action.payload;
      return state;
    },
    addDocxAuthorization: (state, action) => {
      state.docxAuth = [...action.payload];
      return state;
    },
    addSignedDocuments: (state, action) => {
      state.signedDocs = [...action.payload];
      return state;
    },
    addQuickActions: (state, action) => {
      state.quickActions = [...action.payload];
      return state;
    },
    addPermissions: (state, action) => {
      state.permissions = [...action.payload];
      return state;
    },
    canAccessHrDocuments: (state, action) => {
      state.accessHrDocuments = action.payload;
      return state;
    },
    canEditHrDetails: (state, action) => {
      state.editHrDetails = action.payload;
      return state;
    },
    addTasks: (state, action) => {
      state.tasks = [...action.payload];
      return state;
    },
    addBranches: (state, action) => {
      state.branches = [...action.payload]
      return state;
    },
    updateUserValue: (state, action) => {
      const { docxAuth, quickActions, tasks, permissions, signedDocs, branches } =
        action.payload;
      state.docxAuth = [...docxAuth];
      state.quickActions = [...quickActions];
      state.tasks = [...tasks];
      state.permissions = [...permissions];
      state.signedDocs = [...signedDocs];
      state.branches = [...branches]
      state.fetchedStatus = true;
      return state;
    },
    updateFetchedStatus: (state, action) => {
      state.fetchedStatus = action.payload;
      return state;
    }
  },
});

export const {
  addPersonalDetails,
  addDocxAuthorization,
  addSignedDocuments,
  addQuickActions,
  addTasks,
  addBranches,
  addPermissions,
  updateUserValue,
  updateFetchedStatus,
  canAccessHrDocuments,
  canEditHrDetails
} = createUser.actions;

export default createUser.reducer;
